import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/dashboard/Home.vue'
import Product from '../views/dashboard/product.vue'
import ProductGroup from '../views/dashboard/product-group.vue'
import BuyNow from '../views/dashboard/buy-now.vue'
import Cart from '../views/dashboard/cart.vue'
import CategoryProducts from '../views/dashboard/category-products.vue'
import Checkout from '../views/dashboard/checkout.vue'
import ReturnPolicy from '../views/dashboard/return-policy.vue'
import SearchResults from '../views/dashboard/search-results.vue'
import Dashboard from '../views/dashboard/dashboard.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

  {
    path: '/',
    /* name: 'Dashboard', 
     component: Dashboard */
    redirect: '/dashboard/home',
  },

  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,

    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'product/:productId',
        name: 'Product',
        component: Product,
      },
      {
        path: 'product-group/:productGroupId',
        name: 'ProductGroup',
        component: ProductGroup,
      },
      {
        path: 'buy-now',
        name: 'BuyNow',
        component: BuyNow,
      },
      {
        path: 'cart',
        name: 'Cart',
        component: Cart,
      },
      {
        path: 'category/:categoryId',
        name: 'CategoryProducts',
        component: CategoryProducts,
      },
      {
        path: 'checkout',
        name: 'Checkout',
        component: Checkout,
      },
      {
        path: 'return-policy',
        name: 'ReturnPolicy',
        component: ReturnPolicy,
      },
      {
        path: 'search-results',
        name: 'SearchResults',
        component: SearchResults,
      },
      //In case the route is anything else
      {
        path: '*',
        redirect: '/home'
      },

    ]
  },

  //In case the route is anything else
  {
    path: '*',
    redirect: '/home'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
