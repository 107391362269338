<template>
  <div>
    <article>
      <section>
        <div class="eg_introduction_desktop">
          <div class="container">
            <div>
              <div class="eg_introduction">
                <div>
                  <div class="theLists">
                    <ul v-show="isFetching" class="px-0 mt-3 col-12">
                      <li v-for="i in 10" :key="i">
                        <div
                          class="skeleton-block skeleton-effect-wave"
                          style="
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            margin-right: 10px;
                          "
                        ></div>
                        <div
                          class="skeleton-block skeleton-effect-wave"
                          style="
                            width: 100%;
                            height: 30px;
                            border-radius: 5px;
                            margin-right: 10px;
                          "
                        ></div>
                      </li>
                    </ul>
                    <ul>
                      <li
                        v-for="category in categories"
                        :key="category.id"
                        @click="categoryDetail(category.id)"
                      >
                        <div
                          style="
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            margin-right: 10px;
                          "
                        >
                          <img
                            :src="category.category_image"
                            style="
                              width: 30px;
                              height: 30px;
                              border-radius: 50%;
                            "
                          />
                        </div>
                        <small>{{ category.category_name }}</small>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="eg_carousel">
                  <el-carousel height="400px" v-show="isFetching" indicator-position="none">
                    <el-carousel-item v-for="i in 5" :key="i">
                      <div
                        class="skeleton-block skeleton-effect-wave"
                        style="height: 400px"
                      ></div>
                    </el-carousel-item>
                  </el-carousel>

                  <el-carousel height="400px" indicator-position="none">
                    <el-carousel-item v-for="c in categories" :key="c.id">
                      <div class="category_article1">
                        <div>
                          <img :src="c.category_image" />
                          <div></div>
                        </div>
                        <div class="container">
                          <h1 class="fs4 text-white">
                            <strong>{{ c.category_name }}</strong>
                          </h1>
                          <p class="fsp">
                            <span>Shop At Evrythingirly</span>
                            <br /><span>Discover Everything Girly</span>
                          </p>
                          <br />
                        </div>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="eg_introduction_mobile">
          <div class="eg_carousel">
            <el-carousel v-show="isFetching" indicator-position="none">
              <el-carousel-item v-for="i in 5" :key="i">
                <div
                  class="skeleton-block skeleton-effect-wave"
                  style="height: 150px"
                ></div>
              </el-carousel-item>
            </el-carousel>

            <el-carousel indicator-position="none">
              <el-carousel-item v-for="c in categories" :key="c.id">
                <div class="category_article1">
                  <div>
                    <img :src="c.category_image" />
                    <div></div>
                  </div>
                  <div class="container">
                    <h2 class="text-white">
                      <strong>{{ c.category_name }}</strong>
                    </h2>
                    <p class="fsp">
                      <span>Shop At Evrythingirly</span>
                      <br /><span>Discover Everything Girly</span>
                    </p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </section>
    </article>

    <article>
      <section>
        <div class="visible_on_mobile mt-4 mb-4">
          <div class="container">
            <div class="product_group_grid">
              <div @click="$router.push({ name: 'ProductGroup', params: { productGroupId: 4 } })">
                <div>
                  <div class="product_group_image_description">
                    <div>
                      <i class="fas fa-gift"></i>
                    </div>
                  </div>
                  <div class="product_group_text text-center">
                    <small>Gift Ideas</small>
                  </div>
                </div>
              </div>
              <div @click="$router.push({ name: 'ProductGroup', params: { productGroupId: 1 } })">
                <div>
                  <div class="product_group_image_description">
                    <div><i class="fas fa-cart-plus"></i></div>
                  </div>
                  <div class="product_group_text text-center">
                    <small class="text-center">New Arrivals</small>
                  </div>
                </div>
              </div>
              <div @click="$router.push({ name: 'ProductGroup', params: { productGroupId: 1 } })">
                <div>
                  <div class="product_group_image_description">
                    <div><i class="far fa-clock"></i></div>
                  </div>
                  <div class="product_group_text text-center">
                    <small class="text-center">Sets</small>
                  </div>
                </div>
              </div>
              <div @click="$router.push({ name: 'ProductGroup', params: { productGroupId: 5 } })">
                <div>
                  <div class="product_group_image_description">
                    <div><i class="fab fa-product-hunt"></i></div>
                  </div>
                  <div class="product_group_text text-center">
                    <small class="text-center">Solution products</small>
                  </div>
                </div>
              </div>
              <div @click="$router.push({ name: 'CategoryProducts', params: { categoryId: 13 } })">
                <div>
                  <div class="product_group_image_description">
                    <div><i class="fas fa-tshirt"></i></div>
                  </div>
                  <div class="product_group_text text-center">
                    <small class="text-center">Clothings</small>
                  </div>
                </div>
              </div>
              <div @click="$router.push({ name: 'CategoryProducts', params: { categoryId: 14 } })">
                <div>
                  <div class="product_group_image_description">
                    <div><i class="fas fa-bed"></i></div>
                  </div>
                  <div class="product_group_text text-center">
                    <small class="text-center">Beddings</small>
                  </div>
                </div>
              </div>
              <div @click="$router.push({ name: 'CategoryProducts', params: { categoryId: 16 } })">
                <div>
                  <div class="product_group_image_description">
                    <div><i class="far fa-gem"></i></div>
                  </div>
                  <div class="product_group_text text-center">
                    <small class="text-center">Jewellery</small>
                  </div>
                </div>
              </div>
              <div @click="$router.push({ name: 'CategoryProducts', params: { categoryId: 22 } })">
                <div>
                  <div class="product_group_image_description">
                    <div><i class="fas fa-smile-beam"></i></div>
                  </div>
                  <div class="product_group_text text-center">
                    <small class="text-center">Beauty</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article>
      <section>
        <div class="top_rankings mt-5">
          <div class="container">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <h2 style="font-size: 1em">The Live product title</h2>
              <router-link to="/">
                <i class="fas fa-chevron-right"></i>
              </router-link>
            </div>
            <div class="top_rankings_grid">
              <div
                v-for="product in live_product_group_products"
                :key="product.id"
              >
                <div :title="product.id">
                  <div class="top_rankings_grid_image_description">
                    <div>
                      <img :src="product.main_image" />
                    </div>
                  </div>
                  <div class="top_rankings_grid_text text-center">
                    <small>{{ product.product_name }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFetching: true,
      isError: false,

      loading: false,
      loadingError: false,
      categoriesMessage: "",
      ghosts: [],
      prodcuts_ghosts: [],

      currencyData: "",
      p: 1,
      categories: [],
      product_name: "",
      product_price: "",
      quantity: "",
      id: "",
      main_image: "",
      product_description: "",

      category: "",
      products_in_category: "",

      live_product_group: "",
      live_product_group_products: "",
    };
  },

  mounted() {
    this.getCategories();
    this.getLiveProductGroups();
    // this.ghosts = new Array(10);
    // this.prodcuts_ghosts = new Array(20);
    // this.getCurrencyRates();
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    async getCategories() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(`categories`);
        if (request.data.success) {
          this.categories = request.data.categories;
          localStorage.setItem("categories", JSON.stringify(this.categories));
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning(
            "EVRYTHINGIRLY FRONT END: Unexpected API response",
            {
              response: request.data,
              request,
            }
          );
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = true;
        this.isError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Products now, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    getLiveProductGroups() {
      this.loading = true;
      this.loadingError = false;
      this.$http
        .get(`product/group/live`)
        .then(
          (response) => {
            if (response.data.success == true) {
              this.live_product_group = response.data.product_group;
              this.live_product_group_products =
                response.data.products_in_proup;
            } else {
              this.loadingError = true;
              this.showFailedMessage(
                "Fetch Failed",
                "An unexpected Error Occured. Please try again"
              );
            }
          },
          () => {
            this.loadingError = true;
            this.showFailedMessage(
              "Fetch Failed",
              "An unexpected Error Occured. Please try again"
            );
          }
        )
        .finally(() => (this.loading = false));
    },

    categoryDetail(categoryId) {
      this.$router.push({
        name: "CategoryProducts",
        params: {
          categoryId: categoryId,
        },
      });
    },

    // getCurrencyRates() {
    //     alert("THis is s afunction from Angular");
    // },
  },
};
</script>


<style scoped>
.theLists {
  margin: 20px 0px;
  padding: 0px;
}

.fs5 {
  font-size: 1.3em;
}

.visible_on_desktop {
  display: block;
}

.visible_on_mobile {
  display: none;
}

ul {
  padding: 0px;
}
ul li {
  list-style: none;
  text-align: left;
  font-weight: 400;
  color: white;
  cursor: pointer;

  display: flex;
  align-items: center;
  margin: 5px 0px;
}

ul li:hover {
  color: var(--el-app-primary);
}

.eg_introduction {
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  display: flex;
}

.eg_introduction_mobile {
  display: none;
}
.eg_introduction_desktop {
  display: block;
}

.eg_introduction > div {
  height: 400px;
  border-radius: 5px;
}

.eg_introduction > div:first-child {
  background-color: #f74764;
  padding: 10px;
  width: 20%;
}

.eg_introduction > div:nth-child(2) {
  background-color: white;
  width: 80%;
}

.product_group_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.product_group_grid > div {
  display: flex;
  justify-content: center;
}

.product_group_image_description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_group_image_description > div {
  background-color: #f74764;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_group_text {
  width: 100%;
  line-height: 80%;
  padding-top: 8px;
  font-weight: 600;
}

.top_rankings {
  display: none;
  background-color: pink;
  padding-top: 20px;
  padding-bottom: 20px;
}

.top_rankings_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.top_rankings_grid > div {
  background-color: #ec8f97;
  border-radius: 5px;
}

.top_rankings_grid_image_description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_rankings_grid_image_description > div {
  background-color: #f74764;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_rankings_grid_image_description img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.top_rankings_grid_text {
  width: 100%;
  line-height: 80%;
  padding-top: 8px;
  font-weight: 600;
  padding: 5px;
}

.category_article1 img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

.category_article1 {
  position: relative;
}

.category_article1 > div:first-child > {
  position: relative;
}

.category_article1 > div:first-child > div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(
    rgba(236, 143, 151, 0),
    rgba(236, 143, 151, 1)
  );
  width: 100%;
}

.category_article1 > div:nth-child(2) {
  position: absolute;
  bottom: 0px;
  padding: 0px 0px 0px 50px;
  color: white;
  border-radius: 5px;
}

i {
  font-size: 20px;
}

.TitleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divideRuler {
  height: 5px;
  width: 50%;
  box-shadow: inset 0 0 5px #473830;
  border-radius: 10px;
  background-color: #f7f6f5;
}

.fc_grid {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 10px;
  margin-top: 20px;
}

.fc_grid > div:first-child {
  position: relative;
}

.fc_grid > div:first-child > div {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
  padding: 20px 10px;
  border-radius: 5px;
}

.fc_grid > div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.fc_grid > div:nth-child(2) > div {
  height: 200px;
  position: relative;
}

.fc_grid > div:nth-child(2) > div > div {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
  padding: 10px;
  border-radius: 5px;
}

.ghostWidth {
  margin: auto;
  height: 250px;
}
.btn {
  border-radius: 0px;
  padding-left: 10px;
  padding-right: 10px;
}
.griddivs12 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.griddivs12 > section {
  -webkit-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  transition: 0.5s;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
  border-radius: 5px;
}
.trendingImages > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.sold-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  display: flex;
  align-items: center;
}

.griddivs12 > section:hover {
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  transition: 0.5s;
  background-color: #f08684;
  color: white;
}
.griddivs12 > section > div:nth-child(2) {
  padding: 10px 10px;
}

.item {
  transition: all 2s ease;
  overflow: hidden;
  height: 100%;
}

.item img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 400px;
  object-fit: cover;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.item:hover img {
  -webkit-transform: scale(1.15) rotate(0.01deg);
  transform: scale(1.15) rotate(0.01deg);
}

.padding100 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.mb10 {
  margin-bottom: 5%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .TitleDiv {
    display: block;
  }

  .TitleDiv h2 {
    text-align: center;
  }

  .divideRuler {
    height: 5px;
    width: 100%;
    box-shadow: inset 0 0 5px #473830;
    border-radius: 10px;
    background-color: #f7f6f5;
  }

  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  .griddivs12 > section > div:nth-child(2) {
    padding: 10px 10px;
  }
  .trendingImages > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .TitleDiv {
    display: block;
  }

  .TitleDiv h2 {
    text-align: center;
  }

  .divideRuler {
    height: 5px;
    width: 100%;
    box-shadow: inset 0 0 5px #473830;
    border-radius: 10px;
    background-color: #f7f6f5;
  }

  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .griddivs12 > section > div:nth-child(2) {
    padding: 10px 10px;
  }
  .trendingImages > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .visible_on_desktop {
    display: none;
  }
  .visible_on_mobile {
    display: block;
  }

  .category_article1 img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .category_article1 h2 {
    font-size: 1.2em;
  }

  .category_article1 p {
    font-size: 0.8em;
  }

  .item img {
    height: 150px;
  }
  .category_article1 > div:nth-child(2) {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0px 5px;
  }

  .fc_grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 20px;
  }

  .fc_grid > div:first-child > div {
    padding: 20px 10px;
  }

  .fc_grid > div:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .fc_grid > div:nth-child(2) > div {
    height: 150px;
  }
  .TitleDiv {
    display: block;
  }

  .TitleDiv h2 {
    text-align: center;
  }
  .divideRuler {
    height: 5px;
    width: 100%;
    box-shadow: inset 0 0 5px #473830;
    border-radius: 10px;
    background-color: #f7f6f5;
  }

  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .griddivs12 > section > div:nth-child(2) {
    padding: 10px 10px;
  }
  .trendingImages > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .padding100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .eg_introduction_mobile {
    display: block;
    margin-top: 20px;
  }
  .eg_introduction_desktop {
    display: none;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .visible_on_desktop {
    display: none;
  }
  .visible_on_mobile {
    display: block;
  }

  .eg_introduction_mobile {
    display: block;
    margin-top: 20px;
  }
  .eg_introduction_desktop {
    display: none;
  }

  .category_article1 img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .category_article1 h2 {
    font-size: 1.2em;
  }

  .category_article1 p {
    font-size: 0.8em;
  }

  .item img {
    height: 150px;
  }
  .category_article1 > div:nth-child(2) {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0px 20px;
  }

  .fc_grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 20px;
  }

  .fc_grid > div:first-child > div {
    padding: 20px 10px;
  }

  .fc_grid > div:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .fc_grid > div:nth-child(2) > div {
    height: 150px;
  }
  .TitleDiv {
    display: block;
  }

  .fs5 {
    font-size: 1em;
    font-weight: 700;
  }

  .divideRuler {
    height: 2px;
  }

  .myacc1 {
    display: none;
  }
  .backBtn {
    display: block;
  }
  .on_desktop {
    display: none;
  }

  .ghostWidth {
    margin: auto;
    height: 150px;
  }
  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .griddivs12 > section > div:nth-child(2) {
    padding: 10px 10px;
  }
  .trendingImages > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .padding100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .product_show {
    padding: 10px 10px;
  }
}
</style>

<style>
.eg_carousel .el-carousel {
  height: 400px !important;
}

.eg_carousel .el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.eg_carousel .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.eg_carousel .el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .eg_carousel .el-carousel {
    height: 150px !important;
  }
  .eg_carousel .el-carousel__container {
    height: 150px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .eg_carousel .el-carousel {
    height: 150px !important;
  }
  .eg_carousel .el-carousel__container {
    height: 150px;
  }
}
</style>
