<template>
  <div>
    <NavbarComponent />
    <main>

      <article v-if="total_cart_value" style="padding-top: 100px">
        <section>
          <div class="container">
            <section>
              <h2 class="fs4">
                Your bag total is
                <span
                  ><strong>{{
                    formatCurrency(total_cart_value)
                  }}</strong></span
                >.
              </h2>
              <p>Free delivery and free returns.</p>
              <div style="margin-bottom: 20px">
                <button
                  style="padding-left: 40px; padding-right: 40px"
                  class="btn btn-md pinkbtn2"
                  @click="checkout"
                >
                  Check out&nbsp;&nbsp;&nbsp;<i
                    class="fas fa-chevron-right"
                  ></i>
                </button>
              </div>

              <div v-for="(item, index) of items" :key="index" :id="index">
                <div class="myacc2" style="background-color: #f1d0be">
                  <div style="background-color: #f1d0be">
                    <div class="items_grid">
                      <div
                        style="
                          height: 100%;
                          margin: 0px;
                          float: left;
                          padding: 10px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <img
                          :src="item.product.image"
                          style="width: 80px; height: 80px; object-fit: cover"
                        />
                      </div>
                      <div
                        style="
                          border-left: 1px solid rgb(209, 207, 207);
                          border-bottom: 1px solid rgb(209, 207, 207);
                          height: 100%;
                          margin: 0px;
                          padding: 10px;
                          text-align: left;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <div style="color: black">
                          <small
                            ><strong>{{ item.product.item }}</strong></small
                          ><br />
                          <span
                            ><small
                              >Color: {{ item.product.color }}</small
                            ></span
                          ><br />
                          <span
                            ><small>Size: {{ item.product.size }}</small></span
                          ><br />
                        </div>
                      </div>
                      <div
                        style="
                          border-left: 1px solid rgb(209, 207, 207);
                          border-bottom: 1px solid rgb(209, 207, 207);
                          height: 100%;
                          margin: 0px;
                          padding: 10px;
                          text-align: left;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <div class="colors1">
                          <small>
                            Quantity:
                            <el-input-number
                              v-model="item.product.quantity"
                              @change="handleChange"
                              :min="1"
                              :max="1000000"
                              size="mini"
                            ></el-input-number
                          ></small>
                          <br />
                          <span
                            ><small
                              >Cost:
                              {{ formatCurrency(item.product.price) }}</small
                            ></span
                          ><br />
                          <span :id="item.product.product_id"
                            ><small
                              ><strong>Total: </strong
                              >{{
                                formatCurrency(
                                  item.product.price * item.product.quantity
                                )
                              }}</small
                            ></span
                          >
                        </div>
                      </div>
                      <div>
                        <button
                          class="btn btn-default btn-sm"
                          @click="remove(index)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section>
          <div class="container mt-5 mb-5">
            <div class="cart_buttons">
              <div class="cart_flex" 
                style="display: flex; justify-content: space-between;">
                <div style="text-align: left;"
                >
                  <button
                    class="btn btn-sm pinkbtn2"
                    @click="$router.push({ path: '/' })"
                  >
                    <i class="fas fa-chevron-left"></i>
                    Continue Shopping
                  </button>
                </div>

                <div style="text-align: right;"
                >
                  <button class="btn btn-sm pinkbtn2" @click="checkout">
                    Check out <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>

      <article v-else>
        <section>
          <div style="height: 100vh; display: flex; align-items: center; justify-content: center;">
            <div>
              <el-empty description="Your cart is empty!"></el-empty>
              
                  <button class="btn btn-sm pinkbtn2"
                    @click="$router.push({ path: '/' })">
                    Start Shopping
                  </button>
            </div>
          </div>
        </section>
      </article>

    </main>
    <FooterComponent />
  </div>
</template>

<script>
  import NavbarComponent from "../../components/navbar-component.vue";
  import FooterComponent from "../../components/footer-component.vue";
  export default {
    components: { NavbarComponent, FooterComponent },

    data() {
      return {
        loading: false,
        loadingError: false,
        total_cart_value: 0,

        items: [],
        current_currency: "",
        country: "",
        currency_code: "",
        kes_rate: "",
        tzs_rate: "",
        ssp_rate: "",
        rwf_rate: "",
        bif_rate: "",
        ugx_rate: "",
        usd_rate: "",
        currencyData: "",
      };
    },

    mounted() {
      this.getCartItems();
      this.getCurrencyRates();
      this.getCurrentCurrency();
      this.totalCartValue();
    },

    methods: {
      handleChange(value) {
        console.log(value);
        localStorage.setItem("items", JSON.stringify(this.items));
        this.totalCartValue();
      },

      totalCartValue() {
        let cart_items = JSON.parse(localStorage.getItem("items"));
        this.total_cart_value = 0;
        for (let each_product of cart_items) {
          // calculate the total value of the cart items
          const value =
            each_product.product.quantity * each_product.product.price;
          this.total_cart_value += value;
        }
      },

      getCartItems() {
        this.items = JSON.parse(localStorage.getItem("items"));
        console.log("Hello: " + this.items);
        // this.items.reverse();
      },

      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },

      getCurrentCurrency() {
        this.current_currency = this.$store.state.current_currency;
      },

      formatCurrency(amount) {
        console.log("The amount: " + amount);
        let currency = this.$store.state.current_currency;
        // currency = 'USD';

        if (currency == "KES") {
          let new_amount = Math.round((this.kes_rate / this.ugx_rate) * amount);
          return `KES ${this.formatNumber(new_amount)}`;
        } else if (currency == "UGX") {
          return `UGX ${this.formatNumber(amount)}`;
        } else if (currency == "TZS") {
          let new_amount = Math.round((this.tzs_rate / this.ugx_rate) * amount);
          return `TZS ${this.formatNumber(new_amount)}`;
        } else if (currency == "USD") {
          let new_amount = Math.round((this.usd_rate / this.ugx_rate) * amount);
          if (new_amount <= 1) {
            return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`;
          }
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`;
        } else if (currency == "SSP") {
          let new_amount = Math.round((this.ssp_rate / this.ugx_rate) * amount);
          return `SSP ${this.formatNumber(new_amount)}`;
        } else if (currency == "RWF") {
          let new_amount = Math.round((this.rwf_rate / this.ugx_rate) * amount);
          return `RWF ${this.formatNumber(new_amount)}`;
        } else if (currency == "BIF") {
          let new_amount = Math.round((this.bif_rate / this.ugx_rate) * amount);
          return `BIF ${this.formatNumber(new_amount)}`;
        } else {
          // default to UGX
          return `UGX ${this.formatNumber(amount)}`;
        }
      },

      async getCurrencyRates() {
        try {
          let request = await this.$http.get(
            "https://openexchangerates.org/api/latest.json?app_id=8f4b23638b764037902c6e67e559a0d0"
          );
          if (request.data) {
            console.log(request.data);
            this.currencyData = request.data;
            // value for 1 dollar
            this.usd_rate = 1;
            this.ugx_rate = this.currencyData.rates.UGX;
            this.kes_rate = this.currencyData.rates.KES;
            this.tzs_rate = this.currencyData.rates.TZS;
            this.ssp_rate = this.currencyData.rates.SSP;
            this.rwf_rate = this.currencyData.rates.RWF;
            this.bif_rate = this.currencyData.rates.BIF;

            console.log(`the current tzs_rate is ${this.tzs_rate}`);
            this.isFetching = false;
            this.isError = false;
          } else {
            this.$rollbar.warning(
              "EVRYTHINGIRLY FRONT END: Unexpected API response",
              {
                response: request.data,
                request,
              }
            );
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true;
          this.isError = true;

          if (error.message === "Network Error") {
            console.log(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }

          console.log(error);

          console.log(
            "Fetching failed",
            "Unable to fetch Currency Rates now, please try again"
          );
        } finally {
          this.loading = false;
        }
      },

      checkout() {
        this.$router.push({
          name: "Checkout",
          query: {
            page: "fulfillment",
          },
        });
        // if(this.currentUser) {
        //   this.route.navigate(['/checkout'], { queryParams: {page:'fulfillment', tt:grand_total } });
        // }
        // else {
        //   this.route.navigate(['/login'], { queryParams: { page:'fulfillment', return_to: this.route.url, tt:grand_total} });
        // }
      },

      remove(index) {
        // At position index, remove 1
        this.items.splice(index, 1);
        localStorage.setItem("items", JSON.stringify(this.items));
        this.totalCartValue();
      },
    },
  };
</script>

<style scoped>
  .myacc2 {
    width: 80%;
    margin: 10px auto;
  }

  .pinkbtn2 {
    padding-left: 40px;
    padding-right: 40px;
    background-color: #eb4b5c;
    border: 2px solid #eb4b5c;
    border-radius: 5px;
    color: white;
    transition: 0.5s;
  }

  .items_grid {
    display: grid;
    grid-template-columns: 20% 35% 35% 10%;
  }

  .cart_buttons {
    width: 80%;
    margin: auto;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .myacc2 {
      width: 100%;
      margin: 10px auto;
    }

    .items_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .cart_buttons {
      width: 100%;
      margin: auto;
    }

    .pinkbtn2 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .myacc2 {
      width: 100%;
      margin: 10px auto;
    }

    .items_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .cart_buttons {
      width: 100%;
      margin: auto;
    }

    .pinkbtn2 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
</style>


<style>
  .el-empty__description p {
     font-size: 1.5em;
  }
</style>