<template>
  <div class="home">
    <div class="sidebar_divisions">
      <div class="theLists">
        <ul v-show="isFetching" class="px-0 mt-3 col-12">
          <li v-for="i in 10" :key="i">
            <div
              class="skeleton-block skeleton-effect-wave"
              style="
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 10px;
              "
            ></div>
            <div
              class="skeleton-block skeleton-effect-wave"
              style="
                width: 100%;
                height: 30px;
                border-radius: 5px;
                margin-right: 10px;
              "
            ></div>
          </li>
        </ul>

        <ul>
          <li
            v-for="category in categories"
            :key="category.id"
            @click="categoryDetail(category.id)"
          >
            <div>
              <div class="imgContainer">
                <img :src="category.category_image" />
              </div>
              <small>{{ category.category_name }}</small>
            </div>
          </li>
        </ul>
      </div>
      <hr />

      <div>
        <small style="font-weight: 600">Address:</small><br />
        <ul>
          <li>
            <small>
              <i class="fas fa-map-marker-alt"></i>
              Pentagon City Acarde PC475 Kampala, Uganda
            </small>
          </li>
        </ul>
        <ul>
          <li>
            <small>
              <i class="fab fa-whatsapp"></i> : +256 (0) 783 096053
            </small>
          </li>
          <li>
            <small>
              <i class="fas fa-phone-volume"></i> : +256 (0) 756 577893
            </small>
          </li>

          <li>
            <small>
              <a href="mailto:info@evrythingirly.com" style="color: aliceblue">
                <i class="fas fa-envelope"></i> : info@evrythingirly.com
              </a>
            </small>
          </li>
        </ul>
      </div>

      <div>
        <small style="font-weight: 600">Follow us</small><br />

        <a
          style="color: white"
          href="https://www.facebook.com/girlshoppingmalleastafrica"
          target="_blank"
          title="Facebook"
          ><i class="fab fa-facebook-f"></i>
        </a>
        &nbsp;&nbsp;
        <a
          style="color: white"
          href="https://www.instagram.com/everythingirly_eastafrica/"
          target="_blank"
          title="Instagram"
          ><i class="fab fa-instagram"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFetching: true,
      isError: false,
      loading: false,
      categories: [],
    };
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    async getCategories() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(`categories`);
        if (request.data.success) {
          this.categories = request.data.categories;
          // console.log(JSON.stringify(this.categories));
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning(
            "EVRYTHINGIRLY FRONT END: Unexpected API response",
            {
              response: request.data,
              request,
            }
          );
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = true;
        this.isError = true;

        if (error.message === "Network Error") {
          return console.log(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        console.log(
          "Fetching failed",
          "Unable to fetch Products now, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    categoryDetail(categoryId) {
      this.$router.push({
        name: "CategoryProducts",
        params: {
          categoryId: categoryId,
        },
      });
    },
  },
};
</script>


<style scoped>
.sidebar_divisions {
  padding: 0px 10px;
}
.theLists {
  margin: 20px 0px;
  padding: 0px;
}
ul {
  padding: 0px;
}
ul li {
  list-style: none;
  text-align: left;
  font-weight: 400;
  color: var(--el-app-primary);
  margin-bottom: 10px;
}

ul li > div {
  display: flex;
  align-items: center;
}

ul li:hover {
  color: #74a4c5;
}
ul li i {
  margin-right: 5px;
}
.is-active {
  color: white;
  background-color: var(--el-app-primary);
  border-left: 10px solid red;
}
.side-link {
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}
.side-link:hover {
  background-color: #eeeeee;
  padding: 10px 40px;
  transition: 0.5s;
}
.bg-white {
  background-color: white;
}

.imgContainer {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.imgContainer img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

/* style="width: 50px; height: 50px; border-radius: 50%;" */
</style>