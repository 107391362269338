import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    current_currency: 'UGX',
  },
  mutations: {
    //The synchronous way to update our Vuex in our app store.
    setCurrentCurrency(state, payload) {
      state.current_currency = payload;
    }
  },
  actions: {
    //asynchronous
    async setCurrentCurrency(state, payload) {
      state.commit("setCurrentCurrency");
    }

  },
  modules: {
  },
  getters: {
    // getCurrentCurrency(state) {
    //   return state.current_currency;
    // }
    getCurrentCurrency: state => state.current_currency

  }
})
