<template>
  <div>
    <NavbarComponent />
    <main>
        <article>
            <section>
                <div class="container">
                  
                  <div v-if="isFetching" class="px-0 mt-3 col-12">
                    <div class="skeleton-block skeleton-effect-wave" style="height: 300px; width:100%;">
                    </div>
                  </div>
                    <div v-else class="product_group_article1" style="margin-top: 50px;">
                        <div>
                            <img :src="product_group.image">
                            <div></div>
                        </div>
                        <div>
                            <h1 class="fs4 text-white">
                                <strong>{{product_group.title}}</strong>
                            </h1>
                            <p class="fsp">
                                <span>{{ product_group.description }}</span>
                            </p>
                            <br/>
                        </div>
                    </div>
                </div>
            </section>
        </article>
        <article>
            <section class="padding100">
                <div class="container">
                  
                  <div v-show="isFetching" class="px-0 mt-3 col-12">
                    <div class="griddivs12_skeletons">
                      <div v-for="i in 20" :key="i" class="skeleton-block skeleton-effect-wave"></div>
                    </div>
                  </div>
                    <div class="griddivs12" data-aos="-in-down" data-aos-duration="3000">
                        <section class="" v-for="p in products_in_product_group" :key="p.id">
                            <div class="whitebg mb10" style="position: relative; background-color: rgb(232, 247, 250);">
                                <div class="centered item trendingImages" @click="productDetail(p.id)" style="background-color: rgb(236, 143, 151);cursor:pointer;">
                                    <img class="" :src="p.main_image">
                                </div>
                            </div>
                            <div class=""  @click="productDetail(p.id)" style="cursor:pointer;">
                                <div style="display: flex; align-items: center;">
                                    <div class="">
                                        <span><strong>{{ formatCurrency(p.product_price) }}</strong></span><br/>
                                        <span :title="p.product_name"><small>{{ (p.product_name.length>25)? (p.product_name)+'...':(p.product_name) }}</small></span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <br/><br/><br/>
                </div>
            </section>
        </article>
    </main>
    <FooterComponent/>
  </div>
</template>

<script>
import NavbarComponent from "../../components/navbar-component.vue";
import FooterComponent from "../../components/footer-component.vue";
export default {
  components: {
    NavbarComponent,
    FooterComponent,
  },
  data() {
    return {
      isFetching: true,
      isError: false,
      loading: false,
      loadingError: false,
      productGroupId: this.$route.params.productGroupId,
            
        product_group: {},
        products_in_product_group: [],
        
        current_currency: "",
        country: "",
        currency_code: "",
        kes_rate: "",
        tzs_rate: "",
        ssp_rate: "",
        rwf_rate: "",
        bif_rate: "",
        ugx_rate: "",
        usd_rate: "",
    };
  },

  mounted() {
    this.getOneProductGroup();
      this.getCurrencyRates();
      this.getCurrentCurrency();
    // this.ghosts = new Array(10);
    // this.prodcuts_ghosts = new Array(20);
    // this.getCurrencyRates();
  },

  methods: {
    
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },
      
      getCurrentCurrency() {
        this.current_currency = this.$store.state.current_currency;
      },

      formatCurrency(amount) {
        let currency = this.$store.state.current_currency;
        // currency = 'USD';

        if (currency == "KES") {
          let new_amount = Math.round((this.kes_rate / this.ugx_rate) * amount);
          return `KES ${this.formatNumber(new_amount)}`;
        } else if (currency == "UGX") {
          return `UGX ${this.formatNumber(amount)}`;
        } else if (currency == "TZS") {
          let new_amount = Math.round((this.tzs_rate / this.ugx_rate) * amount);
          return `TZS ${this.formatNumber(new_amount)}`;
        } else if (currency == "USD") {
          let new_amount = Math.round((this.usd_rate / this.ugx_rate) * amount);
          if (new_amount <= 1) {
            return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`;
          }
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`;
        } else if (currency == "SSP") {
          let new_amount = Math.round((this.ssp_rate / this.ugx_rate) * amount);
          return `SSP ${this.formatNumber(new_amount)}`;
        } else if (currency == "RWF") {
          let new_amount = Math.round((this.rwf_rate / this.ugx_rate) * amount);
          return `RWF ${this.formatNumber(new_amount)}`;
        } else if (currency == "BIF") {
          let new_amount = Math.round((this.bif_rate / this.ugx_rate) * amount);
          return `BIF ${this.formatNumber(new_amount)}`;
        } else {
          // default to UGX
          return `UGX ${this.formatNumber(amount)}`;
        }
      },

      async getCurrencyRates() {

        try {

          let request = await this.$http.get('https://openexchangerates.org/api/latest.json?app_id=8f4b23638b764037902c6e67e559a0d0');
          if (request.data) {
            console.log(request.data);
            this.currencyData = request.data;
            // value for 1 dollar
            this.usd_rate = 1;
            this.ugx_rate = this.currencyData.rates.UGX;
            this.kes_rate = this.currencyData.rates.KES;
            this.tzs_rate = this.currencyData.rates.TZS;
            this.ssp_rate = this.currencyData.rates.SSP;
            this.rwf_rate = this.currencyData.rates.RWF;
            this.bif_rate = this.currencyData.rates.BIF;

            console.log(`the current tzs_rate is ${this.tzs_rate}`)
            this.isFetching = false;
            this.isError = false;
          } else {
            this.$rollbar.warning("EVRYTHINGIRLY FRONT END: Unexpected API response", {
              response: request.data,
              request,
            });
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true;
          this.isError = true;

          if (error.message === "Network Error") {
            console.log(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }

          console.log(error);

          console.log(
            "Fetching failed",
            "Unable to fetch Currency Rates now, please try again"
          );
        } finally {
          this.loading = false;
        }
      },

    async getOneProductGroup() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(`product/group/${this.productGroupId}`);
        if (request.data.success) {
          this.product_group = request.data.product_group;
          this.products_in_product_group = request.data.products_in_proup;
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning(
            "EVRYTHINGIRLY FRONT END: Unexpected API response",
            {
              response: request.data,
              request,
            }
          );
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = true;
        this.isError = true;

        if (error.message === "Network Error") {
          return console.log(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        console.log(
          "Fetching failed",
          "Unable to fetch Products now, please try again"
        );
      }
    },

    productDetail(productId) {
      this.$router.push({
        name: "Product",
        params: {
          productId: productId,
        },
      });
    },

  },
};
</script>


<style scoped>
  main {
    background-image: linear-gradient(to right, #f9eded,#F5C3C2,#f9eded);
  }
    .product_group_article1 img {
  width: 100%; height: 300px; object-fit: cover;
}
.product_group_article1 {
  margin-top: 50px;
  position: relative;
}
.product_group_article1>div:first-child>{
  position: relative;
}
.product_group_article1>div:first-child>div {
  position: absolute;
  top:0px;
  bottom: 0px;
  background-image: linear-gradient(rgba(236,143,151,0), rgba(236,143,151,1));
  width: 100%;
}
.product_group_article1>div:nth-child(2) {
  position: absolute; 
  bottom: 0px;
  padding: 0px 0px 0px 50px;
  color: white;
}
.griddivs12 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.griddivs12>section {
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
  background-image:linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.1));
}
.trendingImages > img {
  width:100%; 
  height:200px; 
  object-fit: cover;
}
.griddivs12>section:hover {
    -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
    box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
    transition: 0.5s;
  background-color: #F08684;
  color: white;
}
.griddivs12>section>div:nth-child(2) {
  padding:10px 10px;
}

.item {
  transition: all 2s ease;
  overflow: hidden;
  height: 100%;
}
.item img {
  border-radius: 5px 5px 0px 0px; width: 100%; height: 300px; object-fit: cover;
	-webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.item:hover img {
-webkit-transform: scale(1.15) rotate(0.01deg);
transform: scale(1.15) rotate(0.01deg);
}
.padding100 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.mb10 {
  margin-bottom: 5%;
}
.griddivs12_skeletons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.griddivs12_skeletons > div{
  height: 200px;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) { 
  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
    
  .griddivs12_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  .griddivs12_skeletons > div{
    height: 150px;
  }
 }

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 
  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
    
  .griddivs12_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  .griddivs12_skeletons > div{
    height: 150px;
  }
 }

/*##Device = Low Resolution Tablets, Mobiles (Landscape)##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) { 
  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
    
  .griddivs12_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .griddivs12_skeletons > div{
    height: 150px;
  }
 }

/*##Device = Most of the Smartphones Mobiles (Portrait)##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .product_group_article1 img {
    width: 100%; height: 200px; object-fit: cover;
  }
  .item img {
    height: 200px;
  }
  .product_group_article1>div:nth-child(2) {
    position: absolute; 
    bottom: 0px;
    width: 100%;
    padding: 0px 5px;  
    text-align: center;
  }
  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  #trendingImages > img {
    width:100%; 
    height:150px; 
    object-fit: cover;
  }
  .padding100 {
      padding-top: 50px;
      padding-bottom: 50px;
  }
    
  .griddivs12_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .griddivs12_skeletons > div{
    height: 150px;
  }
}
</style>
