<template>
  <div>
    <NavbarComponent />
    <main>
      <div
        v-if="loading"
        style="
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <br />
          <span style="font-weight: 500">Loading Search Results...</span>
        </div>
      </div>

      <section v-else>
        <article>
          <section>
            <div v-if="search_results.length">
              <div
                class="container"
                style="padding-top: 150px; padding-bottom: 100px"
              >
                <div class="TitleDiv">
                  <h2 class="fs5">
                    Your Evrythingirly
                    <strong>{{ search_keyword }}</strong> search results
                  </h2>
                  <div class="divideRuler"></div>
                </div>
                <br />
                <div
                  class="griddivs12"
                  data-aos="-in-down"
                  data-aos-duration="3000"
                >
                  <section class="" v-for="p in search_results" :key="p.id">
                    <div
                      class="whitebg mb10"
                      style="
                        position: relative;
                        background-color: rgb(232, 247, 250);
                      "
                    >
                      <div
                        class="centered item trendingImages"
                        @click="productDetail(p.id)"
                        style="
                          background-color: rgb(236, 143, 151);
                          cursor: pointer;
                        "
                      >
                        <img class="" :src="p.main_image" />
                      </div>

                      <div class="sold-badge">
                        <div
                          v-if="p.number_of_products == 0"
                          style="
                            background-color: red;
                            height: 20px;
                            padding: 0px 10px;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <span style="color: white"
                            ><small>sold out</small></span
                          >
                        </div>

                        <template v-else>
                          <div
                            style="
                              background-color: green;
                              height: 20px;
                              padding: 0px 10px;
                              border-radius: 5px;
                              display: flex;
                              align-items: center;
                            "
                          >
                            <span style="color: white"
                              ><small>In stock</small></span
                            >
                          </div>
                        </template>
                      </div>
                    </div>
                    <div style="cursor: pointer">
                      <div
                        @click="productDetail(p.id)"
                        style="display: flex; align-items: center"
                      >
                        <div class="">
                          <span
                            ><strong>{{
                              formatCurrency(p.product_price)
                            }}</strong></span
                          ><br />
                          <span :title="p.product_name"
                            ><small
                              >{{ (p.product_name.length>25)? (p.product_name)+'...':(p.product_name) }}</small
                            ></span
                          >
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <template v-else>
              <div>
                <div
                  style="
                    height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div class="text-center">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 30px;
                      "
                    >
                      <div
                        style="
                          border-radius: 50%;
                          background-color: white;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <img src="../../assets/images/binoculars.svg" />
                      </div>
                    </div>
                    <p>
                      There are no results for
                      <strong>{{ search_keyword }}</strong
                      >.
                    </p>
                    <small>- Check your spelling for typing errors</small><br />
                    <small>- Try searching with short and simple keywords</small
                    ><br />
                    <small
                      >- Try searching more general terms - you can then filter
                      the search results</small
                    ><br /><br />
                    <!-- <button class="btn btn-lg btn-info">Go to Home page</button> -->
                    <button
                      class="btn pinkbtn2"
                      @click="$router.push({ path: '/' })"
                      style="border-radius: 0px; padding: 5px 50px"
                    >
                      START SHOPPING
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </section>
        </article>
      </section>
    </main>
    <FooterComponent/>
  </div>
</template>

<script>
import NavbarComponent from "../../components/navbar-component.vue";
import FooterComponent from "../../components/footer-component.vue";
export default {
  components: {
    NavbarComponent,
    FooterComponent,
  },
        data() {
            return {
                        
                loading: false,
                loadingError: false, 
                messageAlert: '',
                search_keyword: this.$route.query.search,

                search_results: [],
        current_currency: "",
        country: "",
        currency_code: "",
        kes_rate: "",
        tzs_rate: "",
        ssp_rate: "",
        rwf_rate: "",
        bif_rate: "",
        ugx_rate: "",
        usd_rate: "",

            };
        },

        mounted() {
            this.getAllSearchResults();
      this.getCurrencyRates();
      this.getCurrentCurrency();
        },

        methods: {

            productDetail(productId) {
                this.$router.push({
                    name: "Product",
                    params: {
                    productId: productId,
                    },
                });
            },

            async getAllSearchResults() {
                try {
                    this.loading = true;
                    this.loadingError = false;
                    let request = await this.$http.get(`/product/search?key=${this.search_keyword}`);
                    if (request.data.success) {
                    this.search_results = request.data.products;
                    console.log("CATEGORY DETAILS");
                    } else {
                    throw "UNEXPECTED_RESPONSE";
                    }
                } catch (error) {
                    if (error.message == "Network Error") {
                    this.loadingError = true;
                    return console.log(
                        "Connection failed",
                        "A network error occured, please try again."
                    );
                    }
                    this.loadingError = true;
                    console.log(
                    "Unable to load Search Results",
                    "An unexpected Error occured, please try again"
                    );
                } finally {
                    this.loading = false;
                }
            },

            getAllProducts() {
                this.loading = true;
                this.loadingError = false;
                this.$http
                .get(`products`)
                .then(
                (response) => {
                    if (response.data.success == true) {
                    this.products = response.data.products;
                    } else {
                    this.loadingError = true;
                    this.showFailedMessage(
                        "Fetch Failed",
                        "An unexpected Error Occured. Please try again"
                    );
                    }
                },
                () => {
                    this.loadingError = true;
                    this.showFailedMessage(
                    "Fetch Failed",
                    "An unexpected Error Occured. Please try again"
                    );
                }
                )
                .finally(() => (this.loading = false));
            },
    
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },
      
      getCurrentCurrency() {
        this.current_currency = this.$store.state.current_currency;
      },

      formatCurrency(amount) {
        let currency = this.$store.state.current_currency;
        // currency = 'USD';

        if (currency == "KES") {
          let new_amount = Math.round((this.kes_rate / this.ugx_rate) * amount);
          return `KES ${this.formatNumber(new_amount)}`;
        } else if (currency == "UGX") {
          return `UGX ${this.formatNumber(amount)}`;
        } else if (currency == "TZS") {
          let new_amount = Math.round((this.tzs_rate / this.ugx_rate) * amount);
          return `TZS ${this.formatNumber(new_amount)}`;
        } else if (currency == "USD") {
          let new_amount = Math.round((this.usd_rate / this.ugx_rate) * amount);
          if (new_amount <= 1) {
            return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`;
          }
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`;
        } else if (currency == "SSP") {
          let new_amount = Math.round((this.ssp_rate / this.ugx_rate) * amount);
          return `SSP ${this.formatNumber(new_amount)}`;
        } else if (currency == "RWF") {
          let new_amount = Math.round((this.rwf_rate / this.ugx_rate) * amount);
          return `RWF ${this.formatNumber(new_amount)}`;
        } else if (currency == "BIF") {
          let new_amount = Math.round((this.bif_rate / this.ugx_rate) * amount);
          return `BIF ${this.formatNumber(new_amount)}`;
        } else {
          // default to UGX
          return `UGX ${this.formatNumber(amount)}`;
        }
      },

      async getCurrencyRates() {

        try {

          let request = await this.$http.get('https://openexchangerates.org/api/latest.json?app_id=8f4b23638b764037902c6e67e559a0d0');
          if (request.data) {
            console.log(request.data);
            this.currencyData = request.data;
            // value for 1 dollar
            this.usd_rate = 1;
            this.ugx_rate = this.currencyData.rates.UGX;
            this.kes_rate = this.currencyData.rates.KES;
            this.tzs_rate = this.currencyData.rates.TZS;
            this.ssp_rate = this.currencyData.rates.SSP;
            this.rwf_rate = this.currencyData.rates.RWF;
            this.bif_rate = this.currencyData.rates.BIF;

            console.log(`the current tzs_rate is ${this.tzs_rate}`)
            this.isFetching = false;
            this.isError = false;
          } else {
            this.$rollbar.warning("EVRYTHINGIRLY FRONT END: Unexpected API response", {
              response: request.data,
              request,
            });
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true;
          this.isError = true;

          if (error.message === "Network Error") {
            console.log(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }

          console.log(error);

          console.log(
            "Fetching failed",
            "Unable to fetch Currency Rates now, please try again"
          );
        } finally {
          this.loading = false;
        }
      },

        }
    };
</script>

<style scoped>
    main {
        background-image: linear-gradient(to right, #f9eded,#F5C3C2,#f9eded);
        min-height: 100vh;
        /* margin-top: 100px !important; */
    }
    .pinkbtn2 {
        background-color: #EB4B5C;
        border: 2px solid #EB4B5C;
        border-radius: 10px;
        color: white;
        transition: 2s;
    }
    .TitleDiv{
        display: flex; align-items: center; justify-content: space-between;
    }
    
    .divideRuler{
        height: 5px; width: 50%; box-shadow: inset 0 0 5px #473830;  border-radius: 10px; background-color: #f7f6f5;
    }
    .btn {
        border-radius: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .griddivs12 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
    }
    .griddivs12>section {
        -webkit-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
        transition: .5s;
        background-image:linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.1));
    }
    .sold-badge {
        position: absolute;
        top: 5px;
        right:5px;
        height: 20px;
        display: flex;
        align-items: center;
    }
    .griddivs12>section:hover {
        -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
        -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
        box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
        transition: .5s;
        background-color: #F08684;
        color: white;
    }
    .griddivs12>section>div:nth-child(2) {
        padding:10px 10px;
    }
    .item {
        transition: all 2s ease;
        overflow: hidden;
        height: 100%;
    }
    .item img {
        border-radius: 5px 5px 0px 0px; width: 100%; height: 300px; object-fit: cover;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
    }
    .item:hover img {
        -webkit-transform: scale(1.15) rotate(0.01deg);
        transform: scale(1.15) rotate(0.01deg);
    }
    .mb10 {
        margin-bottom: 5%;
    }
    /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
    @media (min-width: 768px) and (max-width: 1024px) { 
        .TitleDiv{
            display: block;
        }
        .TitleDiv h2{
            text-align: center;
        }
        .divideRuler{
            height: 5px; width: 100%; box-shadow: inset 0 0 5px #473830;  border-radius: 10px; background-color: #f7f6f5;
        }
        .griddivs12 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
        }
        .griddivs12>section>div:nth-child(2) {
            padding:10px 10px;
        }
    }
    /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        .TitleDiv{
            display: block;
        }
        .TitleDiv h2{
            text-align: center;
        }
        .divideRuler{
            height: 5px; width: 100%; box-shadow: inset 0 0 5px #473830;  border-radius: 10px; background-color: #f7f6f5;
        }
        .griddivs12 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 10px;
        }
        .griddivs12>section>div:nth-child(2) {
            padding:10px 10px;
        }
    }
    /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
    @media (min-width: 481px) and (max-width: 767px) {
    
        .TitleDiv{
        display: block;
        }
    
        .TitleDiv h2{
        text-align: center;
        }
        .divideRuler{
        height: 5px; width: 100%; box-shadow: inset 0 0 5px #473830;  border-radius: 10px; background-color: #f7f6f5;
        }
    
    
    .griddivs12 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
    }
    .griddivs12>section>div:nth-child(2) {
      padding:10px 10px;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .item img {
      height: 300px;
    }
    .TitleDiv{
      display: block;
    }
  
    .TitleDiv h2{
      text-align: center;
    }
  
    .divideRuler{
      height: 5px; width: 100%; box-shadow: inset 0 0 5px #473830;  border-radius: 10px; background-color: #f7f6f5;
    }
    .on_desktop{
      display: none;
    }
    .griddivs12 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
    .griddivs12>section>div:nth-child(2) {
      padding:10px 10px;
    }
    .trendingImages > img {
      width:100%; 
      height:150px; 
      object-fit: cover;
    }
  }
</style>
