<template>
    <div class="home">
        <transition name="router-anim">
            <router-view />
        </transition>
    </div>
</template>

<script>
// import NavbarComponent from "../../components/navbar-component.vue";
// import FooterComponent from "../../components/footer-component.vue";
export default {
  components: {
    // NavbarComponent,
    // FooterComponent,
  },

      data() {
        return {
          value1: true,
          value2: true
        }
      },

      // then, in the parent component,
        // watch the `$route` to determine the transition to use
        watch: {
        '$route' (to, from) {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
        }
    }

  };
</script>

<style scoped>
.home {
    
        background-image: linear-gradient(to right, #f9eded,#F5C3C2,#f9eded);
}
.page {
  position: fixed;
  width: inherit;
}

.router-anim-enter-active {
  animation: coming 1s;
  animation-delay: .5s;
  opacity: 0;
}

.router-anim-leave-active {
  animation: going 1s;
}

@keyframes going  {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50px);
    opacity: 0;
  }
}

@keyframes coming  {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>