<template>
  <div>
    <NavbarComponent />
    <main>
      <article>
        <section>
          <div>
            <section style="padding-top: 70px">
              <div class="lightgrey">
                <div class="container">
                  <div
                    style="
                      border-bottom: 1px solid grey;
                      padding: 10px 0px 0px 0px;
                      display: flex;
                      justify-content: space-between;
                    ">
                    <div>Checkout</div>
                    <div>
                      Order:
                      <span v-if="delivery_pickup_option == 'pick_up'">
                          {{ formatCurrency( total_cart_value ) }}
                      </span>
                      <span v-else>
                          {{ formatCurrency( total_cart_value + 5000 ) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </article>

      <div v-if="page_status == 'fulfillment'">
        <article>
          <section style="padding-top: 50px">
            <div class="lightgrey">
              <div class="container">
                <div class="text-center">
                  <h1 class="fs4">
                    <strong>How would you like to get your order?</strong>
                  </h1>
                  <br />
                  <div class="delivery_pickup_div">
                    <div>
                      <el-radio-group
                        v-model="delivery_pickup_option"
                      >
                        <el-radio-button
                          label="delivery"
                        >Would you like it delivered?</el-radio-button>
                        <el-radio-button
                          label="pick_up"
                        >I'll pick it up</el-radio-button>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <br /><br />
          <div class="container">
            <section>
              <div v-if="delivery_pickup_option == 'delivery'">
                <div class="delivery_grid">
                  <div style="background-color: #eeeeee; padding: 20px">
                    <div>

                        <el-form :model="shippingForm" :rules="shippingFormRules" label-position="top" ref="shippingRuleForm" label-width="120px" class="demo-shippingRuleForm">
                          <div class="row">
                              <div class="col-md-6">
                                  <el-form-item label="First name" prop="fname">
                                      <el-input v-model="shippingForm.fname"></el-input>
                                  </el-form-item>
                              </div>
                              <div class="col-md-6">
                                  <el-form-item label="Last name" prop="lname">
                                      <el-input v-model="shippingForm.lname"></el-input>
                                  </el-form-item>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-md-6">
                                  <el-form-item label="Phone Number" prop="phone">
                                      <el-input v-model="shippingForm.phone"></el-input>
                                  </el-form-item>
                              </div>
                              <div class="col-md-6">
                                  <el-form-item label="Country" prop="country">
                                      <el-select v-model="shippingForm.country" placeholder="Select Country">
                                          <el-option 
                                            v-for="country in country_list" :key="country"
                                            :label="country"     
                                            :value="country">
                                          </el-option>
                                      </el-select>
                                  </el-form-item>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-md-12">
                                  <el-form-item label="Place to deliver" prop="address">
                                      <el-input v-model="shippingForm.address"></el-input>
                                  </el-form-item>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-md-12">
                                  <el-form-item label="Email" prop="email">
                                      <el-input v-model="shippingForm.email"></el-input>
                                  </el-form-item>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-md-12"> 
                                  <el-form-item>
                                      <el-button type="primary" 
                                          @click="ContinueToPayment('shippingRuleForm')"
                                          >Continue To Payment 
                                          <i class="fas fa-long-arrow-alt-right"></i>
                                      </el-button>
                                  </el-form-item>
                              </div>
                          </div>
                        </el-form>
                    </div>
                  </div>
                  <div style="padding: 20px; text-align: left;">
                    <p>
                      <strong>Keep this in mind about your selection:</strong>
                    </p>
                    <ul>
                      <li>
                        A standard delivery fee of
                        <strong style="color: red">{{
                          formatCurrency(5000)
                        }}</strong>
                        will be added.
                      </li>
                      <li>
                        No-Contact Standard or Express Delivery: Drivers may ask
                        for verbal confirmation from a safe distance to satisfy
                        the signature requirement, or may leave lower-cost
                        shipments at your door with no signature required.
                      </li>
                      <li>
                        In-Transit Options: Once your order has been shipped, you
                        can use your tracking link to redirect your shipment to a
                        pickup point, hold it at a secure location, or fill out a
                        signature waiver or shipment release.
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="width50btn">
                              <button class="btn btn-block" @click="Shipping()" style="background-color: #F74764; color: white;">
                                  Continue to Shipping Address <i class="fas fa-long-arrow-alt-right"></i></button>
                          </div> -->
              </div> 
              <div v-else-if="delivery_pickup_option == 'pick_up'">
                <div class="pickUp_grid">
                  <div style="background-color: #f7f7f7; padding: 20px">
                    <div>
                      <form>
                        <!-- [formGroup]="pickupForm" -->
                        <fieldset>
                          <legend class="fs4">
                            <strong>Select your pickup store:</strong>
                          </legend>
                          <br />

                          <div>
                              <el-radio v-model="pickup_point" label="1" border>View Park Towers 6th floor(Pesamoni), Utalii Street
                                                      - Nairobi Kenya</el-radio>
                              <el-radio v-model="pickup_point" label="2" border>Pentagon City Acarde PC475 Kampala</el-radio>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                  <div style="padding: 20px">
                    <p><strong>In-Store</strong></p>
                    <p>
                      Pick up your online order. You may be able to get setup help
                      and shop for accessories. Temperature checks and face masks
                      may be required.
                    </p>
                    <p>
                      We’ll email detailed pickup instructions when your order is
                      ready.
                    </p>
                    <p>
                      Wait times may vary.You will be given an estimated wait time
                      when you check in. This time may vary based on Specialist
                      availability.
                    </p>
                  </div>
                </div>
                <div class="width50btn">
                  <button
                    class="btn btn-block"
                    style="background-color: #f74764; color: white"
                    @click="PickUp()"
                  >
                    Continue to Pickup Details
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </button>
                </div>
              </div>
            </section>
          </div>
          <br /><br />
        </article>

        <article>
          <section>
            <div class="container">
              <hr />
              <div>
                <div style="display: flex">
                  <div style="padding-left: 30px">
                    <p></p>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </section>
        </article>

        <article>
          <section>
            <div class="container">
              <section>
                    <h2 class="fs4">
                      <strong>In stock and ready to ship.</strong>
                    </h2>
                      
                  <div
                  v-for="(item, index) of items"
                  :key="index"
                  :id="index"
                  >
                      <div class="myacc2" style="background-color: #f1d0be">
                          <div style="background-color: #f1d0be">
                              <div style="display: grid; grid-template-columns: 20% 40% 40%; ">
                                  <div
                                  style="
                                      height: 100%;
                                      margin: 0px;
                                      float: left;
                                      padding: 10px;
                                      display: flex;
                                      align-items: center;
                                  "
                                  >
                                  <img
                                      :src="item.product.image"
                                      style="width: 80px; height: 80px; object-fit: cover"
                                  />
                                  </div>
                                  <div
                                  style="
                                      border-left: 1px solid rgb(209, 207, 207);
                                      border-bottom: 1px solid rgb(209, 207, 207);
                                      height: 100%;
                                      margin: 0px;
                                      padding: 10px;
                                      text-align: left;
                                      display: flex;
                                      align-items: center;
                                  "
                                  >
                                  <div style="color: black">
                                      <small
                                      ><strong>{{ item.product.item }}</strong></small
                                      ><br />
                                      <span
                                      ><small>Color: {{ item.product.color }}</small></span
                                      ><br />
                                      <span
                                      ><small>Size: {{ item.product.size }}</small></span
                                      ><br />
                                  </div>
                                  </div>
                                  <div
                                  style="
                                      border-left: 1px solid rgb(209, 207, 207);
                                      border-bottom: 1px solid rgb(209, 207, 207);
                                      height: 100%;
                                      margin: 0px;
                                      padding: 10px;
                                      text-align: left;
                                      display: flex;
                                      align-items: center;
                                  "
                                  >
                                  <div class="colors1">
                                      <small>Quantity: {{item.product.quantity}}</small><br/>
                                      <span
                                      ><small>Cost: {{
                                          formatCurrency(item.product.price)
                                      }}</small></span><br/>
                                      <span :id="item.product.product_id"
                                      ><small
                                          ><strong>Total: </strong
                                          >{{
                                          formatCurrency(
                                              item.product.price * item.product.quantity
                                          )
                                          }}</small
                                      ></span
                                      >
                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div><br/><br/>
              </section>
            </div>
          </section>
        </article>
      </div>

      <div v-else-if="page_status == 'shipping'"
        style="padding-top: 50px; padding-bottom: 50px"
      >
        <article>
          <section>
            <div class="container"></div>
          </section>
        </article>
      </div>

      <div v-else-if="page_status == 'PickupContact'"
        style="padding-top: 50px; padding-bottom: 50px"
      >
        <article>
          <section>
            <div class="container">
              <div class="width50btn">
                <h2 class="fs3">
                  <strong>Now fill out your pickup information.</strong>
                </h2>
                <br />
                <form>
                  <!-- [formGroup]="pickInfoForm" (ngSubmit)="payment_pickup()" -->
                  <fieldset>
                    <p>Enter your name and address:</p>
                    <div class="form-group">
                      <input
                        type="text"
                        formControlName="fname"
                        class="form-control"
                        placeholder="First Name"
                      />
                      <div class="invalid-feedback">
                        <div>
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        formControlName="lname"
                        class="form-control"
                        placeholder="Last Name"
                      />
                      <div
                        v-if="submitted && pyp.lname.errors"
                        class="invalid-feedback"
                      >
                        <div>
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        formControlName="phone"
                        class="form-control"
                        placeholder="Phone Nummber"
                      />
                      <div
                        v-if="submitted && pyp.phone.errors"
                        class="invalid-feedback"
                      >
                        <div>
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        formControlName="email"
                        class="form-control"
                        placeholder="Email Address"
                      />
                      <div
                        v-if="submitted && pyp.email.errors"
                        class="invalid-feedback"
                      >
                        <div>
                          Country is required
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!-- <button class="btn btn-primary btn-block" @click="payment_pickup()">Continue to Payment</button> -->

                  <button
                    :disabled="loading"
                    class="btn btn-block"
                    style="background-color: #f74764; color: white"
                    @click="payment_pickup()"
                  >
                    Continue to Payment
                    <i class="fas fa-long-arrow-alt-right"></i>
                    <span
                      v-if="loading"
                      class="spinner-border spinner-border-sm mr-1"
                    ></span>
                  </button>
                </form>
              </div>
            </div>
          </section>
        </article>
      </div>

      <div v-else-if="page_status == 'billing'"
        style="padding-top: 50px; padding-bottom: 50px"
      >
        <article>
          <section>
            <div class="container">
              <div class="width70">
                <h2 class="fs3 text-center">
                  <strong>How do you want to pay?</strong>
                </h2>
                <br />
                <div class="pay_flex">
                  <div></div>
                  <div id="pay_momo" @click="pay_momo()" class="text-center">
                    <div
                      class="text-center"
                      style="border-bottom: 1px solid grey"
                    >
                      <img width="15%" src="../../assets/images/payments/mtn.svg" /> /
                      <img width="15%" src="../../assets/images/payments/airtel.svg" />
                    </div>
                    <div class="mt-1">MTN or Airtel Phone Number</div>
                  </div>
                  <div></div>
                </div>

                <br />

                <!----############################ THE CARD PAYMENT SECTION payementMethod == 'card' ############################---->
                <div id="pay_card_div">
                  <div>
                    <p>Thank you for choosing VISA/MASTER card payment option.</p>
                    <form>
                      <!-- [formGroup]="confirmCardForm" (ngSubmit)="confirmCardOrder()" -->
                      <fieldset>
                        <label>Country/Region</label>
                        <el-select v-model="shippingForm.country" placeholder="Select Country">
                                          <el-option 
                                            v-for="country in country_list" :key="country"
                                            :label="country"     
                                            :value="country">
                                          </el-option>
                                      </el-select>
                      </fieldset>
                      <fieldset>
                        <div class="row">
                          <div class="col-md-6">
                            <br />
                            <label>Card Number</label>
                            <input
                              type="text"
                              formControlName="cardnumber"
                              class="form-control inputpad"
                              placeholder="2341 6543 7890 2341"
                              style="padding-left: 10%; padding-right: 10%"
                            />
                          </div>
                          <div class="col-md-6">
                            <br />
                            <label for="cardNames">Names on Card</label>
                            <input
                              type="text"
                              id="cardNames"
                              formControlName="holdername"
                              class="form-control inputpad"
                              placeholder="LAWRENCE LWASA"
                              style="padding-left: 10%; padding-right: 10%"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <br />
                            <label>Expiration *</label>
                            <input
                              type="text"
                              formControlName="exp"
                              class="form-control inputpad"
                              placeholder="06/25"
                              style="padding-left: 10%; padding-right: 10%"
                            />
                          </div>
                          <div class="col-md-6">
                            <br />
                            <label>Security Code</label>
                            <input
                              type="text"
                              class="form-control"
                              formControlName="cvv"
                              placeholder="456"
                              style="padding-left: 10%; padding-right: 10%"
                            />
                          </div>
                        </div>
                      </fieldset>
                      <br />
                      <div>
                        <button :disabled="loading" class="btn btn-lg pinkbtn">
                          <span
                            v-if="loading"
                            class="spinner-border spinner-border-sm mr-1"
                          ></span>
                          Confirm Payment
                        </button>
                        <br />
                      </div>
                    </form>
                  </div>
                </div>
                <!----############################ THE CARD PAYMENT SECTION ############################---->

                <!----############################ THE MTN MOBILE MONEY PAYMENT SECTION payementMethod == 'momo'############################---->
                <div id="pay_momo_div">
                  <div>
                    <form>
                      <!-- [formGroup]="confirmMoMoForm" (ngSubmit)="confirmMoMoOrder()" -->
                      <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                          <div class="text-center">
                            <div
                              class="text-center NoBox"
                              style="display: flex; border: 1px solid #eeeeee"
                            >
                              <input
                                type="button"
                                value="07"
                                style="
                                  padding: 5px 0px 5px 10px;
                                  border: none;
                                  background-color: white;
                                  border-radius: 0px;
                                "
                              />
                              <input
                                type="text"
                                formControlName="mtnNo"
                                placeholder="XXXXXXXX"
                                maxlength="8"
                                style="
                                  padding: 5px 10px 5px 0px;
                                  border: none;
                                  border-radius: 0px;
                                "
                              />
                              <div
                                v-if="submitted && cmo.mtnNo.errors"
                                class="invalid-feedback"
                              >
                                <div v-if="cmo.mtnNo.errors.required">
                                  Mobile Phone Number is required
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center">
                            <br />
                            <button class="btn btn-md btn-block pinkbtn">
                              Confirm Payment
                            </button>
                            <br />
                          </div>
                        </div>
                        <div class="col-md-4"></div>
                      </div>
                    </form>
                  </div>
                </div>
                <!----############################ THE MTN MOBILE MONEY SECTION ############################---->

                <!----############################ THE MPESA PAYMENT SECTION payementMethod == 'mpesa' ############################---->
                <div id="pay_mpesa_div">
                  <div>
                    <form>
                      <!-- [formGroup]="confirmMpesaForm" (ngSubmit)="confirmMpesaOrder()" -->
                      <div>
                        <p>Thank you for choosing Mpesa payment option.</p>
                        <div class="row">
                          <div class="col-md-6">
                            <p>Mpesa Phone</p>
                            <div
                              class="NoBox"
                              style="display: flex; border: 1px solid #eeeeee"
                            >
                              <input
                                type="button"
                                value="+254"
                                style="
                                  padding: 5px 0px 5px 10px;
                                  border: none;
                                  background-color: white;
                                  border-radius: 0px;
                                "
                              />
                              <input
                                type="text"
                                formControlName="mpesaNo"
                                placeholder="XXXXXXXX"
                                maxlength="9"
                                style="
                                  padding: 5px 10px 5px 0px;
                                  border: none;
                                  border-radius: 0px;
                                "
                              />
                              <div
                                v-if="submitted && cmpo.mpesaNo.errors"
                                class="invalid-feedback"
                              >
                                <div v-if="cmpo.mpesaNo.errors.required">
                                  Mpesa Phone Number is required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <br />
                        <button class="btn btn-lg pinkbtn">
                          Confirm Payment
                        </button>
                        <br />
                      </div>
                    </form>
                  </div>
                </div>
                <!----############################ THE MPESA PAYMENT SECTION ############################---->
              </div>
            </div>
          </section>
        </article>
      </div>
    </main>
    <FooterComponent/>
  </div>
</template>

<script>
import NavbarComponent from "../../components/navbar-component.vue";
import FooterComponent from "../../components/footer-component.vue";
export default {
  components: {
    NavbarComponent,
    FooterComponent,
  },
    data() {
      return {
          buy_now_item: {},
          page_status: this.$route.query.page,
          delivery_pickup_option: 'delivery',
          pickup_point: '',

            total_cart_value: 0,
            total_cart_value_on_change: 0,
                                
            items: [],
            cart_items: [],
            total_number_of_items_in_cart: 0,
            total_items: 0,

          shippingForm: {
              fname: '',
              lname: '',
              email: '',
              phone: '',
              address: '',
              country: '',
              shippingMethod: 'delivery',
          },

          shippingFormRules: {
            fname: [
              { required: true, message: 'Please input First Name', trigger: 'blur' },
            ],
            lname: [
              { required: true, message: 'Please input Last Name', trigger: 'blur' },
            ],
            email: [
              { required: false }
            ],
            phone: [
              { required: true, message: 'Please enter phone number', trigger: 'blur' }
            ],
            address: [
              { required: true, message: 'Please enter Address', trigger: 'blur' }
            ],
            country: [
              { required: true, message: 'Please select your Country', trigger: 'change' }
            ]
          },

        transaction_status: false,
        verify_message: "",

        loading: false,
        submitted: false,
        returnUrl: "",
        
        shippingDetails: "",
        products: [],

        country_list: [
          "Uganda",
          "Kenya",
          "Tanzania",
          "Rwanda",
          "Burundi",
          "South Sudan",
        ],
        
        current_currency: "",
        country: "",
        currency_code: "",
        kes_rate: "",
        tzs_rate: "",
        ssp_rate: "",
        rwf_rate: "",
        bif_rate: "",
        ugx_rate: "",
        usd_rate: "",

      };
    },

    mounted() {
      this.getProductDetails();
      this.items = JSON.parse(localStorage.getItem('items'));
        this.totalCartValue();
      this.getCurrencyRates();
      this.getCurrentCurrency();
    },

    computed: {
    },

    methods: {

      handleChange(value) {
        console.log(value);
      },
    
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },
      
      getCurrentCurrency() {
        this.current_currency = this.$store.state.current_currency;
      },

      formatCurrency(amount) {
        let currency = this.$store.state.current_currency;
        // currency = 'USD';

        if (currency == "KES") {
          let new_amount = Math.round((this.kes_rate / this.ugx_rate) * amount);
          return `KES ${this.formatNumber(new_amount)}`;
        } else if (currency == "UGX") {
          return `UGX ${this.formatNumber(amount)}`;
        } else if (currency == "TZS") {
          let new_amount = Math.round((this.tzs_rate / this.ugx_rate) * amount);
          return `TZS ${this.formatNumber(new_amount)}`;
        } else if (currency == "USD") {
          let new_amount = Math.round((this.usd_rate / this.ugx_rate) * amount);
          if (new_amount <= 1) {
            return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`;
          }
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`;
        } else if (currency == "SSP") {
          let new_amount = Math.round((this.ssp_rate / this.ugx_rate) * amount);
          return `SSP ${this.formatNumber(new_amount)}`;
        } else if (currency == "RWF") {
          let new_amount = Math.round((this.rwf_rate / this.ugx_rate) * amount);
          return `RWF ${this.formatNumber(new_amount)}`;
        } else if (currency == "BIF") {
          let new_amount = Math.round((this.bif_rate / this.ugx_rate) * amount);
          return `BIF ${this.formatNumber(new_amount)}`;
        } else {
          // default to UGX
          return `UGX ${this.formatNumber(amount)}`;
        }
      },

      async getCurrencyRates() {

        try {

          let request = await this.$http.get('https://openexchangerates.org/api/latest.json?app_id=8f4b23638b764037902c6e67e559a0d0');
          if (request.data) {
            console.log(request.data);
            this.currencyData = request.data;
            // value for 1 dollar
            this.usd_rate = 1;
            this.ugx_rate = this.currencyData.rates.UGX;
            this.kes_rate = this.currencyData.rates.KES;
            this.tzs_rate = this.currencyData.rates.TZS;
            this.ssp_rate = this.currencyData.rates.SSP;
            this.rwf_rate = this.currencyData.rates.RWF;
            this.bif_rate = this.currencyData.rates.BIF;

            console.log(`the current tzs_rate is ${this.tzs_rate}`)
            this.isFetching = false;
            this.isError = false;
          } else {
            this.$rollbar.warning("EVRYTHINGIRLY FRONT END: Unexpected API response", {
              response: request.data,
              request,
            });
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true;
          this.isError = true;

          if (error.message === "Network Error") {
            console.log(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }

          console.log(error);

          console.log(
            "Fetching failed",
            "Unable to fetch Currency Rates now, please try again"
          );
        } finally {
          this.loading = false;
        }
      },

        totalNumberOfCartItems() {
            this.cart_items = JSON.parse(localStorage.getItem('items'));
            this.total_items = 0;
            for (const item of this.cart_items) {
            this.total_items += Number.parseInt(item.product.quantity);
            }
            this.total_number_of_items_in_cart = this.total_items;
        },
           
        totalCartValue() {
            this.cart_items = JSON.parse(localStorage.getItem('items'));
            this.total_cart_value = 0;
            for (this.each_product of this.cart_items) {
            // calculate the total value of the cart items
            const value = this.each_product.product.quantity * this.each_product.product.price;
            this.total_cart_value += value;
            }
        },

      getProductDetails() {
        this.buy_now_item = JSON.parse(localStorage.getItem("buy_item"));
        console.log(this.buy_now_item);
      },

      ContinueToPayment(shippingRuleForm) {
          this.$refs[shippingRuleForm].validate((valid) => {
            if (valid) {
              this.shippingDetails = this.shippingForm;
              if (localStorage.getItem('shippingDetails') != null) {
              localStorage.setItem('shippingDetails', JSON.stringify(this.shippingDetails));
              }else {
              localStorage.setItem('shippingDetails', JSON.stringify(this.shippingDetails));
              }
              this.page_status = "billing"
              this.$router.push({
                  name: "Checkout",
                  query: {
                      page: 'billing' 
                  },
              }).catch(()=>{
                console.log('Error Occured.')
              });
              this.loading = false;
            } else {
              console.log('error submit!!');
              return false;
            }
          });
      },
      
    },
  };
</script>

<style scoped>
  .delivery_pickup_div {
    width: 45%;
    margin: auto;
    border-radius: 50px;
  }
  .delivery_pickup_grid {
    padding: 0px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .delivery_pickup_grid > div:first-child {
    border: 2px solid grey;
    padding: 10px 10px;
    border-radius: 50px 0px 0px 50px;
  }
  .delivery_pickup_grid > div:nth-child(2) {
    border: 2px solid grey;
    padding: 10px 10px;
    border-radius: 0px 50px 50px 0px;
  }
  .delivery_pickup_grid > div:first-child:hover {
    border: 2px solid #f08684;
  }
  .delivery_pickup_grid > div:nth-child(2):hover {
    border: 2px solid #f08684;
  }

  ul li {
    font-size: 0.8em;
  }

  .width50btn {
    width: 50%;
  }

  .width70 {
    width: 100%;
  }

  #pickUp {
    display: none;
  }

  #delivery_grid {
    background-color: #f08684;
    border: 2px solid #f08684;
    cursor: pointer;
  }

  #pickup_grid {
    cursor: pointer;
  }

  .pickUp_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .delivery_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  input:focus {
    outline: none;
  }
  .NoBox {
    outline: 2px dashed #eeeeee;
    background-color: white;
  }
  .NoBox:hover {
    outline: 2px dashed #adabab;
  }

  .inputGroup {
    background-color: rgb(247, 236, 238);
    display: block;
    margin: 10px 0;
    position: relative;
  }
  .inputGroup label {
    /* padding: 12px 30px; */
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    -webkit-transition: color 200ms ease-in;
    transition: color 200ms ease-in;
    overflow: hidden;
  }
  .inputGroup label:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: "";
    background-color: #f08684;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }
  .inputGroup label:after {
    width: 32px;
    height: 32px;
    content: "";
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
  }
  .inputGroup input:checked ~ label {
    color: #fff;
  }
  .inputGroup input:checked ~ label:before {
    -webkit-transform: translate(0%, 0%) scale3d(56, 56, 1);
    transform: translate(0%, 0%) scale3d(100, 100, 1);
    opacity: 1;
  }
  .inputGroup input:checked ~ label:after {
    background-color: #54e0c7;
    border-color: #54e0c7;
  }
  .inputGroup input {
    width: 32px;
    height: 32px;
    -webkit-box-ordinal-group: 2;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }

  .pay_flex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .pay_flex > div:nth-child(2) {
    border: 2px solid grey;
    border-radius: 20px;
    padding: 10px 20px;
  }

  .pay_flex > div:nth-child(2):hover {
    border: 2px solid #f85653;
  }

  #pay_card_div {
    display: none;
  }
  #pay_momo_div {
    display: block;
  }
  #pay_mpesa_div {
    display: none;
  }

  #pay_card_div,
  #pay_momo_div,
  #pay_mpesa_div {
    padding: 20px 0px;
  }

  .pinkbtn {
    background-color: rgb(247, 71, 100);
    border: 2px solid rgb(247, 71, 100);
    color: white;
  }

  ul {
    
  }

  /* ##Device = Desktops ##Screen = 2561px to higher resolution desktops */
  @media (min-width: 2561px) {
  }

  /* ##Device = Laptops, Desktops ##Screen = B/w 2306px to 2560px */
  @media (min-width: 2306px) and (max-width: 2560px) {
  }

  /* ##Device = Laptops, Desktops ##Screen = B/w 2050px to 2305px */
  @media (min-width: 2050px) and (max-width: 2305px) {
  }

  /* ##Device = Laptops, Desktops ##Screen = B/w 1794px to 2049px */
  @media (min-width: 1794px) and (max-width: 2049px) {
  }

  /* ##Device = Laptops, Desktops ##Screen = B/w 1572px to 1793px */
  @media (min-width: 1572px) and (max-width: 1793px) {
  }

  /* ##Device = Laptops, Desktops ##Screen = B/w 1281px to 1573px */
  @media (min-width: 1281px) and (max-width: 1573px) {
  }

  /* ##Device = Laptops, Desktops ##Screen = B/w 1025px to 1280px */
  @media (min-width: 1025px) and (max-width: 1280px) {
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .delivery_pickup_div {
      width: 100%;
      margin: auto;
      border-radius: 50px;
    }

    .delivery_pickup_grid > div:first-child {
      border: 2px solid grey;
      padding: 10px 10px;
      border-radius: 30px 0px 0px 30px;
    }
    .delivery_pickup_grid > div:nth-child(2) {
      border: 2px solid grey;
      padding: 20px 10px;
      border-radius: 0px 30px 30px 0px;
    }
    .pickUp_grid {
      display: grid;
      grid-template-columns: 1fr;
    }

    .delivery_grid {
      display: grid;
      grid-template-columns: 1fr;
    }

    .width50btn {
      width: 100%;
    }

    .width70 {
      width: 100%;
    }

    .pay_flex {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }

    .pay_flex > div:nth-child(2) {
      border: 2px solid grey;
      border-radius: 20px;
      padding: 5px 10px;
    }

    .pay_flex > div:nth-child(2):hover {
      border: 2px solid #f85653;
    }

    #pay_card_div,
    #pay_momo_div,
    #pay_mpesa_div {
      /* padding:20px; border: 1px solid #eeeeee; */
    }

    .pinkbtn {
      background-color: rgb(247, 71, 100);
      border: 2px solid rgb(247, 71, 100);
      color: white;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .delivery_pickup_div {
      width: 100%;
      margin: auto;
      border-radius: 50px;
    }

    .delivery_pickup_grid > div:first-child {
      border: 2px solid grey;
      padding: 10px 10px;
      border-radius: 30px 0px 0px 30px;
    }
    .delivery_pickup_grid > div:nth-child(2) {
      border: 2px solid grey;
      padding: 20px 10px;
      border-radius: 0px 30px 30px 0px;
    }
    .pickUp_grid {
      display: grid;
      grid-template-columns: 1fr;
    }

    .delivery_grid {
      display: grid;
      grid-template-columns: 1fr;
    }

    .width50btn {
      width: 100%;
    }

    .width70 {
      width: 100%;
    }

    .pay_flex {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }

    .pay_flex > div:nth-child(2) {
      border: 2px solid grey;
      border-radius: 20px;
      padding: 5px 10px;
    }

    .pay_flex > div:nth-child(2):hover {
      border: 2px solid #f85653;
    }

    #pay_card_div,
    #pay_momo_div,
    #pay_mpesa_div {
      /* padding:20px; border: 1px solid #eeeeee; */
    }

    .pinkbtn {
      background-color: rgb(247, 71, 100);
      border: 2px solid rgb(247, 71, 100);
      color: white;
    }
  }
</style>

<style>
  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #FFF;
    background-color: rgb(247, 71, 100);
    border-color: rgb(247, 71, 100);
    box-shadow: -1px 0 0 0 rgb(247, 71, 100);
  }

  .el-form-item {
    text-align: left;
    margin-bottom: 10px;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0 0 0px;
  }

  label {
    margin-bottom: 0rem !important;
}
</style>