<template>
  <div>
    <nav
      class="navbar navbar-expand-lg fixed-top"
      style="padding-right: 0px !important; padding-left: 0px !important"
    >
      <div id="searchOff">
        <div class="container">
          <div style="display: flex; align-items: center">
            <div class="menucontainer" id="menucontainer" @click="openNav">
              <div class="bar1"></div>
              <div class="bar2"></div>
              <div class="bar3"></div>
            </div>

            <router-link to="/">
              <img src="../assets/images/eglogo1.png" id="brandlogo_desktop" />
              <img src="../assets/images/eglogo21.png" id="brandlogo_mobile" />
            </router-link>
          </div>
          
          <div class="theSearchSectionDesktop">
            <div class="theSearchSection" style="border: 1px solid #f74764">
              <input
                class="form-control form-control-sm"
                v-model="name"
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Search..."
                style="border-radius: 0px"
                @keyup.enter="getSearchResults"
              />
              <button
                class="btn btn-sm"
                style="
                  border-radius: 0px;
                  width: 80px;
                  background-color: #f74764;
                  color: white;
                "
                @click="getSearchResults"
              >
                <i class="fas fa-search"></i>
              </button>
              <datalist id="datalistOptions">
                <!-- <option value="San Francisco">
                        <option value="New York">
                        <option value="Seattle">
                        <option value="Los Angeles">
                        <option value="Chicago"> -->
              </datalist>
            </div>
          </div>

          <div class="DesktopIcons">
            <div style="display: flex; align-items: center">
              <div class="theSearchSectionMob">
                <span
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3d6d5;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                  "
                >
                  <i class="fas fa-search" style="color: #f74764"></i
                ></span>
              </div>

              <div style="margin: 0px 5px 0px 5px">
                <!-- <div>
                  <a><i class="fas fa-user"></i></a>
                </div> -->
                <div
                  title="Signin /Signup"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f74764;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                  "
                >
                  <a><i class="fas fa-user"></i></a>
                </div>
              </div>

              <div style="margin: 0px 5px 0px 5px">
                <router-link to="/cart">
                  <i
                    id="cart_items"
                    class="fas fa-shopping-cart"
                    style="color: white"
                  >
                  </i>
                </router-link>
              </div>

              <div class="box">
                <el-select
                  v-model="current_currency"
                  @change="chooseCurrency"
                  placeholder="Select"
                  size="mini"
                >
                  <el-option
                    v-for="item in supported_currencies"
                    :key="item.key"
                    :label="item.text"
                    :value="item.key"
                    :selected="selected_currency == item.key"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="MobileIcons">
            <div class="box">
              <div>
                <el-select
                  v-model="current_currency"
                  @change="chooseCurrency"
                  placeholder="Select"
                  size="mini"
                >
                  <el-option
                    v-for="item in supported_currencies"
                    :key="item.key"
                    :label="item.text"
                    :value="item.key"
                    :selected="selected_currency == item.key"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="MobileIcons">
            <div style="display: flex; align-items: center">
              <div style="margin: 0px 5px 0px 5px">
                <router-link to="/cart">
                  <i
                    id="cart_items"
                    class="fas fa-shopping-cart"
                    style="color: white"
                  >
                  </i>
                </router-link>
              </div>
              <div class="theSearchSectionMob" @click="openSearch">
                <span
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3d6d5;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                  "
                >
                  <i class="fas fa-search" style="color: #f74764"></i
                ></span>
              </div>
              <div style="margin: 0px 5px 0px 5px">
                <div>
                  <a><i class="fas fa-user"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="searchOn">
        <div class="container" style="margin-top: 20px">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div>
              <button
                class="btn btn-sm"
                @click="closeSearch"
                style="background-color: #f74764; color: white"
              >
                <i class="fas fa-backward"></i>
              </button>
            </div>
            <div
              class="theSearchSection"
              style="border: 1px solid #f74764; width: 100%"
            >
              <input
                class="form-control form-control-sm"
                v-model="name"
                placeholder="Search..."
                style="border-radius: 0px"
                @keyup.enter="getSearchResults"
              />
              <button
                class="btn btn-sm"
                style="
                  border-radius: 0px;
                  width: 80px;
                  background-color: #f74764;
                  color: white;
                "
                @click="getSearchResults"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <section>
      <div id="mySidenav" class="sidenav">
        <div class="sidenavDivisions">
          <div>
            <SidebarComponent></SidebarComponent>
          </div>
          <div @click="closeNav"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SidebarComponent from "./sidebar-component.vue";

export default {
  components: {
    SidebarComponent,
  },
  data() {
    return {
      name: "",

      responseData: "",
      categories: [],
      items_in_cart: 5,
      cart_items: "",
      each_item: "",
      items_in_wlist: "",
      wlist_items: "",
      each_wlitem: "",
      selected_currency: "",
      loading: false,
      currentUserPhoto: "",

      current_currency: "",

      supported_currencies: [
        { key: "UGX", value: "UGX", text: "🇺🇬 UGX" },
        { key: "KES", value: "KES", text: "🇰🇪 KES" },
        { key: "USD", value: "USD", text: "🇺🇸 USD" },
        { key: "BIF", value: "BIF", text: "🇧🇮 BIF" },
        { key: "RWF", value: "RWF", text: "🇷🇼 RWF" },
        { key: "SSP", value: "SSP", text: "🇸🇸 SSP" },
        { key: "TZS", value: "TZS", text: "🇹🇿 TZS" },
      ],
    };
  },

  mounted() {
    this.ItemsInCart();
    this.getCurrentCurrency();
    this.mycountry();
  },

  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "100%";
    },

    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },

    openSearch() {
      // alert("This is incredible");
      document.getElementById("searchOn").style.display = "block";
      document.getElementById("searchOff").style.display = "none";
    },

    closeSearch() {
      document.getElementById("searchOn").style.display = "none";
      document.getElementById("searchOff").style.display = "block";
    },

    openContact() {
      const ww = document.body.clientWidth;
      if (ww < 500) {
        document.getElementById("mySidecontact").style.width = "60%";
        document.getElementById("mySidenav").style.width = "0";
      } else if (ww >= 501) {
        document.getElementById("mySidecontact").style.width = "30%";
        document.getElementById("mySidenav").style.width = "0";
      }
    },

    chooseCurrency() {
      this.$store.commit('setCurrentCurrency', this.current_currency)
    },

    getCurrentCurrency() {
      this.current_currency = this.$store.getters.getCurrentCurrency;
    },

    async mycountry() {
      try {
        let request = await this.$http.get(
          "https://api.ipdata.co/?api-key=5e051ac0f5efa2e17515cecaf23181e39c695b2500c151c98bd3021b"
        );
        if (request.data) {
          // console.log(request.data);
          this.country = request.data.country_name;
          this.currency_code = request.data.currency.code;
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning(
            "EVRYTHINGIRLY FRONT END: Unexpected API response",
            {
              response: request.data,
              request,
            }
          );
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = true;
        this.isError = true;

        if (error.message === "Network Error") {
          console.log(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        console.log(
          "Fetching failed",
          "Unable to fetch Country Code now, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    ItemsInCart() {
      this.cart_items = JSON.parse(localStorage.getItem("items"));
      this.items_in_cart = 0;
      // console.log(this.cart_items.length);
      if (this.cart_items != null) {
        if (this.cart_items.length != 0) {
          for (this.each_item of this.cart_items) {
            // calculate the total number of the cart items
            let cartitems = this.each_item.product.quantity;
            this.items_in_cart += cartitems;
          }
        }
      }
      document.getElementById(
        "cart_items"
      ).innerHTML = ` ${this.items_in_cart}`;
    },

    getSearchResults() {
      this.closeSearch();
      // this.router.push(['/search-results'], { queryParams: { search: `${this.name}` } });

      this.$router.push({
        path: "/search-results",
        query: {
          search: `${this.name}`,
        },
      })
      .catch(() => {
              console.log("Error Occured.");
            });
    },
  },
};
</script>

<style scoped>
nav {
  background-color: #f5c3c2;
}

#brandlogo_desktop {
  display: block;
  width: 158px;
  /* padding-left: 20px; #F5C3C2*/
}
#brandlogo_mobile {
  display: none;
  width: 40px;
  padding-left: 0px;
}
.theSearchSection {
  display: flex;
  width: 500px;
}
.theSearchSectionDesktop {
  display: block;
}
.theSearchSectionMob {
  margin: 0px 5px 0px 5px;
  display: none;
}
.navlist li a {
  color: white;
}
.navlist li a:hover {
  color: grey;
}
ul {
  list-style: none;
  padding: 0;
}
ul li {
  cursor: pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  color: rgb(236, 102, 124);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999;
}
.dropdown-content a {
  float: none;
  color: rgb(236, 102, 124);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.menucontainer {
  display: none;
}
.bar1,
.bar2,
.bar3 {
  display: none;
}

/************************/
.box select {
  background-color: #ec9694;
  color: white;
  padding: 2px 5px;
  width: 100%;
  border: none;
  font-size: 15px;
  /* box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2); */
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}
.box select option {
  padding: 30px;
}
/***********************/
*:focus {
  outline: none !important;
}
a {
  color: white;
}
.sidenav {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: 0.2s;
}

.sidenav .sidenavDivisions {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.sidenav .sidenavDivisions > div:first-child {
  background-color: #f74764;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}

.box {
  width: 100px;
}

/******************CUSTOM SCROLLBAR for sidenavDivisions>div:first-child*******************/
/* width */
.sidenav .sidenavDivisions > div:first-child::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidenav .sidenavDivisions > div:first-child::-webkit-scrollbar-track {
  background: pink;
}

/* Handle */
.sidenav .sidenavDivisions > div:first-child::-webkit-scrollbar-thumb {
  background: #f11d5a;
}

/* Handle on hover */
.sidenav .sidenavDivisions > div:first-child::-webkit-scrollbar-thumb:hover {
  background: rgb(150, 6, 47);
}
/******************CUSTOM SCROLLBAR*******************/

.sidenav .sidenavDivisions > div:nth-child(2) {
  background-color: rgba(0, 0, 0, 0.8);
}

.sidenav a {
  padding: 0px 8px 0px 32px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: block;
  transition: 0.2s;
}
.sidenav a:hover {
  color: white;
}
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

/* <!----THE COUNTRY SELECTION STYLING----> */

/* <!----THE COUNTRY SELECTION STYLING----> */

.DesktopIcons {
  display: block;
}
.MobileIcons {
  display: none;
}

#searchOff {
  display: block;
  width: 100%;
}

#searchOn {
  display: none;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  #brandlogo_desktop {
    display: none;
  }
  #brandlogo_mobile {
    display: block;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #brandlogo_desktop {
    display: none;
  }
  #brandlogo_mobile {
    display: block;
    width: 50px;
    padding-left: 0px;
  }

  /* .DesktopIcons {
      display: none;
    }
    .MobileIcons {
      display: block;
    } */
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape)##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  #brandlogo_desktop {
    display: none;
  }
  #brandlogo_mobile {
    display: block;
  }

  .theSearchSectionDesktop {
    display: none;
  }
  .theSearchSectionMob {
    display: block;
  }

  .DesktopIcons {
    display: none;
  }
  .MobileIcons {
    display: block;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait)##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  #brandlogo_desktop {
    display: none;
  }
  #brandlogo_mobile {
    display: block;
  }

  .theSearchSectionDesktop {
    display: none;
  }
  .theSearchSectionMob {
    display: block;
  }

  .menucontainer {
    display: inline-block;
    cursor: pointer;
    margin-right: 8px;
  }
  .bar1,
  .bar2,
  .bar3 {
    display: block;
    width: 26px;
    height: 2.5px;
    background-color: #f74764;
    margin: 5px 0;
    transition: 0.4s;
  }

  .DesktopIcons {
    display: none;
  }
  .MobileIcons {
    display: block;
  }
}
</style>
