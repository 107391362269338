<template>
  <div class="home">
    <article>
      <section class="padding100">
        <div class="container">
          <div class="TitleDiv">
            <h2 class="fs5">Products on Evrythingirly</h2>
            <div class="divideRuler"></div>
          </div>
          
          <br />
          <div v-show="isFetching" class="px-0 mt-3 col-12">
            <div class="products_skeletons">
              <div v-for="i in 20" :key="i" class="skeleton-block skeleton-effect-wave"></div>
            </div>
          </div>


          <div class="griddivs12" data-aos="-in-down" data-aos-duration="3000">
            <section class="" v-for="p in products" :key="p.id">
              <div class="whitebg mb10"
                style="position: relative; background-color: rgb(232, 247, 250); border-radius: 5px;"
              >
                <div @click="productDetail(p.id)"
                  class="centered item trendingImages"
                  style="background-color: rgb(236, 143, 151); cursor: pointer"
                >
                  <img class="" :src="p.product_image_1" />
                </div>
                <div class="sold-badge">
                  <div
                    v-if="p.number_of_products == 0"
                    style="
                      background-color: red;
                      height: 20px;
                      padding: 0px 10px;
                      border-radius: 5px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span style="color: white"><small>sold out</small></span>
                  </div>

                  <template v-else>
                    <div
                      style="
                        background-color: green;
                        height: 20px;
                        padding: 0px 10px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <span style="color: white"><small>In stock</small></span>
                    </div>
                  </template>
                </div>
              </div>
              <div class="" style="cursor: pointer">
                <div style="display: flex; align-items: center">
                  <div class="">
                    <span
                      ><strong>{{
                        formatCurrency(p.product_price)
                      }}</strong></span
                    ><br />
                    <span :title="p.product_name"
                      ><small>{{
                        p.product_name.length > 25
                          ? p.product_name + "..."
                          : p.product_name
                      }}</small></span
                    >
                  </div>
                </div>
              </div>
            </section>
          </div>
          <br />
          
        </div>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        
        isFetching: true,
        isError: false,
        products: [],
        loading: false,
        categoriesMessage: "",
        ghosts: "",
        prodcuts_ghosts: [],
        currencyData: "",
        p: 1,

        responseData: "",
        categories: [],
        product_name: "",
        product_price: "",
        quantity: "",
        id: "",
        main_image: "",
        product_description: "",

        current_currency: "",
        country: "",
        currency_code: "",
        kes_rate: "",
        tzs_rate: "",
        ssp_rate: "",
        rwf_rate: "",
        bif_rate: "",
        ugx_rate: "",
        usd_rate: "",

        category: "",
        products_in_category: "",

        live_product_group: "",
        live_product_group_products: "",
      };
    },

    mounted() {
      this.getAllProducts();
      this.getCurrencyRates();
      this.getCurrentCurrency();
    },

    methods: {
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },
      
      getCurrentCurrency() {
        this.current_currency = this.$store.state.current_currency;
      },

      async getAllProducts() {
        try {
          //If we havent been loading yet, let's Load
          if (!this.isFetching) {
            this.isFetching = true;
            this.isError = false;
          }

          let request = await this.$http.get(`products`);
          if (request.data.success) {
            this.products = request.data.products;
            this.isFetching = false;
            this.isError = false;
          } else {
            this.$rollbar.warning("EVRYTHINGIRLY FRONT END: Unexpected API response", {
              response: request.data,
              request,
            });
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true;
          this.isError = true;

          if (error.message === "Network Error") {
            console.log(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }

          console.log(error);

          console.log(
            "Fetching failed",
            "Unable to fetch Products now, please try again"
          );
        } finally {
          this.loading = false;
        }
      },

      productDetail(productId) {
        this.$router.push({
          name: "Product",
          params: {
            productId: productId,
          },
        });
      },

      formatCurrency(amount) {
        let currency = this.$store.state.current_currency;
        // currency = 'USD';

        if (currency == "KES") {
          let new_amount = Math.round((this.kes_rate / this.ugx_rate) * amount);
          return `KES ${this.formatNumber(new_amount)}`;
        } else if (currency == "UGX") {
          return `UGX ${this.formatNumber(amount)}`;
        } else if (currency == "TZS") {
          let new_amount = Math.round((this.tzs_rate / this.ugx_rate) * amount);
          return `TZS ${this.formatNumber(new_amount)}`;
        } else if (currency == "USD") {
          let new_amount = Math.round((this.usd_rate / this.ugx_rate) * amount);
          if (new_amount <= 1) {
            return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`;
          }
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`;
        } else if (currency == "SSP") {
          let new_amount = Math.round((this.ssp_rate / this.ugx_rate) * amount);
          return `SSP ${this.formatNumber(new_amount)}`;
        } else if (currency == "RWF") {
          let new_amount = Math.round((this.rwf_rate / this.ugx_rate) * amount);
          return `RWF ${this.formatNumber(new_amount)}`;
        } else if (currency == "BIF") {
          let new_amount = Math.round((this.bif_rate / this.ugx_rate) * amount);
          return `BIF ${this.formatNumber(new_amount)}`;
        } else {
          // default to UGX
          return `UGX ${this.formatNumber(amount)}`;
        }
      },

      async getCurrencyRates() {

        try {

          let request = await this.$http.get('https://openexchangerates.org/api/latest.json?app_id=8f4b23638b764037902c6e67e559a0d0');
          if (request.data) {
            console.log(request.data);
            this.currencyData = request.data;
            // value for 1 dollar
            this.usd_rate = 1;
            this.ugx_rate = this.currencyData.rates.UGX;
            this.kes_rate = this.currencyData.rates.KES;
            this.tzs_rate = this.currencyData.rates.TZS;
            this.ssp_rate = this.currencyData.rates.SSP;
            this.rwf_rate = this.currencyData.rates.RWF;
            this.bif_rate = this.currencyData.rates.BIF;

            console.log(`the current tzs_rate is ${this.tzs_rate}`)
            this.isFetching = false;
            this.isError = false;
          } else {
            this.$rollbar.warning("EVRYTHINGIRLY FRONT END: Unexpected API response", {
              response: request.data,
              request,
            });
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true;
          this.isError = true;

          if (error.message === "Network Error") {
            console.log(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }

          console.log(error);

          console.log(
            "Fetching failed",
            "Unable to fetch Currency Rates now, please try again"
          );
        } finally {
          this.loading = false;
        }
      }

    },
  };
</script>

<style scoped>
.theLists {
  margin: 20px 0px;
  padding: 0px;
}

.fs5 {
  font-size: 1.3em;
}

.visible_on_desktop {
  display: block;
}

.visible_on_mobile {
  display: none;
}

ul {
  padding: 0px;
}
ul li {
  list-style: none;
  text-align: left;
  font-weight: 400;
  color: white;
  cursor: pointer;

  display: flex;
  align-items: center;
  margin: 5px 0px;
}

ul li:hover {
  color: var(--el-app-primary);
}

.product_group_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.product_group_grid > div {
  display: flex;
  justify-content: center;
}

.product_group_image_description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_group_image_description > div {
  background-color: #f74764;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_group_text {
  width: 100%;
  line-height: 80%;
  padding-top: 8px;
  font-weight: 600;
}

.top_rankings {
  background-color: pink;
  padding-top: 20px;
  padding-bottom: 20px;
}

.top_rankings_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.top_rankings_grid > div {
  background-color: #ec8f97;
  border-radius: 5px;
}

.top_rankings_grid_image_description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_rankings_grid_image_description > div {
  background-color: #f74764;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_rankings_grid_image_description img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.top_rankings_grid_text {
  width: 100%;
  line-height: 80%;
  padding-top: 8px;
  font-weight: 600;
  padding: 5px;
}

.category_article1 img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

.category_article1 {
  position: relative;
}
.category_article1 > div:first-child > {
  position: relative;
}
.category_article1 > div:first-child > div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(
    rgba(236, 143, 151, 0),
    rgba(236, 143, 151, 1)
  );
  width: 100%;
}
.category_article1 > div:nth-child(2) {
  position: absolute;
  bottom: 0px;
  padding: 0px 0px 0px 50px;
  color: white;
  border-radius: 5px;
}

.el-carousel {
  height: 400px !important;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

i {
  font-size: 20px;
}

.TitleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divideRuler {
  height: 5px;
  width: 50%;
  box-shadow: inset 0 0 5px #473830;
  border-radius: 10px;
  background-color: #f7f6f5;
}

.fc_grid {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 10px;
  margin-top: 20px;
}

.fc_grid > div:first-child {
  position: relative;
}

.fc_grid > div:first-child > div {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
  padding: 20px 10px;
  border-radius: 5px;
}

.fc_grid > div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.fc_grid > div:nth-child(2) > div {
  height: 200px;
  position: relative;
}

.fc_grid > div:nth-child(2) > div > div {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
  padding: 10px;
  border-radius: 5px;
}

.ghostWidth {
  margin: auto;
  height: 250px;
}
.btn {
  border-radius: 0px;
  padding-left: 10px;
  padding-right: 10px;
}
.griddivs12 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.griddivs12 > section {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
  border-radius: 5px;
}
.trendingImages {
  border-radius: 5px;
}
.trendingImages > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.sold-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  display: flex;
  align-items: center;
}

.griddivs12 > section:hover {
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  transition: 0.5s;
  background-color: #f08684;
  color: white;
}
.griddivs12 > section > div:nth-child(2) {
  padding: 10px 10px;
}

.item {
  transition: all 2s ease;
  overflow: hidden;
  height: 100%;
}

.item img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.item:hover img {
  -webkit-transform: scale(1.15) rotate(0.01deg);
  transform: scale(1.15) rotate(0.01deg);
}

.padding100 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.mb10 {
  margin-bottom: 5%;
}

.products_skeletons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.products_skeletons >div {
  height: 200px; border-radius: 5px
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .TitleDiv {
    display: block;
  }

  .TitleDiv h2 {
    text-align: center;
  }

  .divideRuler {
    height: 5px;
    width: 100%;
    box-shadow: inset 0 0 5px #473830;
    border-radius: 10px;
    background-color: #f7f6f5;
  }

  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  .griddivs12 > section > div:nth-child(2) {
    padding: 10px 10px;
  }
  .trendingImages > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .TitleDiv {
    display: block;
  }

  .TitleDiv h2 {
    text-align: center;
  }

  .divideRuler {
    height: 5px;
    width: 100%;
    box-shadow: inset 0 0 5px #473830;
    border-radius: 10px;
    background-color: #f7f6f5;
  }

  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .griddivs12 > section > div:nth-child(2) {
    padding: 10px 10px;
  }
  .trendingImages > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .visible_on_desktop {
    display: none;
  }
  .visible_on_mobile {
    display: block;
  }

  .category_article1 img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .category_article1 h2 {
    font-size: 1.2em;
  }

  .category_article1 p {
    font-size: 0.8em;
  }

  .item img {
    height: 150px;
  }
  .category_article1 > div:nth-child(2) {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0px 5px;
  }

  .fc_grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 20px;
  }

  .fc_grid > div:first-child > div {
    padding: 20px 10px;
  }

  .fc_grid > div:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .fc_grid > div:nth-child(2) > div {
    height: 150px;
  }
  .TitleDiv {
    display: block;
  }

  .TitleDiv h2 {
    text-align: center;
  }
  .divideRuler {
    height: 5px;
    width: 100%;
    box-shadow: inset 0 0 5px #473830;
    border-radius: 10px;
    background-color: #f7f6f5;
  }

  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .griddivs12 > section > div:nth-child(2) {
    padding: 10px 10px;
  }
  .trendingImages > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .padding100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .visible_on_desktop {
    display: none;
  }
  .visible_on_mobile {
    display: block;
  }

  .category_article1 img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .category_article1 h2 {
    font-size: 1.2em;
  }

  .category_article1 p {
    font-size: 0.8em;
  }

  .item img {
    height: 150px;
  }
  .category_article1 > div:nth-child(2) {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0px 20px;
  }

  .fc_grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 20px;
  }

  .fc_grid > div:first-child > div {
    padding: 20px 10px;
  }

  .fc_grid > div:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .fc_grid > div:nth-child(2) > div {
    height: 150px;
  }
  .TitleDiv {
    display: block;
  }

  .fs5 {
    font-size: 1em;
    font-weight: 700;
  }

  .divideRuler {
    height: 2px;
  }

  .myacc1 {
    display: none;
  }
  .backBtn {
    display: block;
  }
  .on_desktop {
    display: none;
  }

  .ghostWidth {
    margin: auto;
    height: 150px;
  }
  .griddivs12 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .griddivs12 > section > div:nth-child(2) {
    padding: 10px 10px;
  }
  .trendingImages > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .padding100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .product_show {
    padding: 10px 10px;
  }

  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .products_skeletons >div {
    height: 150px; border-radius: 5px
  }
}
</style>