<template>
  <div>
    <NavbarComponent />
    <div class="home">
      <article class="article1_space">
        <section>
          <div class="container">
            <div class="">
              <ul class="breadcrumb">
                <li>
                  <router-link to="/">
                    <small>Home</small>
                  </router-link>
                </li>
                <li>
                  <a @click="categoryDetail(product.category_id)">
                    <small>Categories</small>
                  </a>
                </li>
                <li>
                  <small>{{
                    product.product_name ? product.product_name : "..."
                  }}</small>
                </li>
              </ul>
              
            </div>
            <div class="product_detail_grid">
              <div>
                <div class="product_images">
                  <div>
                    <div
                      v-if="isFetching"
                      class="skeleton-block skeleton-effect-wave"
                      style="width: 100%; height: 120px"
                    ></div>
                    <div v-else>
                      <img
                        :src="product.main_image"
                        @click="showIt(product.main_image)"
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      v-if="isFetching"
                      class="skeleton-block skeleton-effect-wave"
                      style="width: 100%; height: 120px"
                    ></div>
                    <div v-else>
                      <img
                        :src="product.product_image_1"
                        @click="showIt(product.product_image_1)"
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      v-if="isFetching"
                      class="skeleton-block skeleton-effect-wave"
                      style="width: 100%; height: 120px"
                    ></div>
                    <div v-else>
                      <img
                        :src="product.product_image_2"
                        @click="showIt(product.product_image_2)"
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      v-if="isFetching"
                      class="skeleton-block skeleton-effect-wave"
                      style="width: 100%; height: 120px"
                    ></div>
                    <div v-else>
                      <img
                        :src="product.product_image_3"
                        @click="showIt(product.product_image_3)"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    v-if="isFetching"
                    class="skeleton-block skeleton-effect-wave"
                    style="width: 100%; height: 500px"
                  ></div>
                  <div v-else class="p_image">
                    <img :src="product.main_image" id="imageshow" />
                  </div>
                </div>
              </div>
              <div>
                <div v-if="isFetching">
                  <div class="">
                    <div class="griddivs12">
                      <div
                        v-for="i in 3"
                        :key="i"
                        class="skeleton-block skeleton-effect-wave mb-1"
                      ></div>
                      <div class="mb-5 mt-5">
                        <div
                          v-for="i in 3"
                          :key="i"
                          class="skeleton-block skeleton-effect-wave mb-2"
                          style="width: 50%"
                        ></div>
                      </div>
                      <div class="mb-5">
                        <div
                          v-for="i in 3"
                          :key="i"
                          class="skeleton-block skeleton-effect-wave mb-2"
                        ></div>
                      </div>
                      <div
                        class="mb-3"
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          grid-gap: 20px;
                        "
                      >
                        <div
                          v-for="i in 2"
                          :key="i"
                          class="skeleton-block skeleton-effect-wave mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div v-if="product.product_name">
                    <h2 class="fs5">{{ product.product_name }}</h2>
                  </div>

                  <div v-if="product.product_price">
                    <span class="fs3" style="color: black"
                      ><strong>{{
                        formatCurrency(product.product_price)
                      }}</strong></span
                    >
                  </div>

                  <div class="">
                    <p><span v-html="product.product_description"></span></p>
                  </div>
                  <hr />

                  <div v-if="colors.length">
                    <span>Color: </span>
                    <el-radio-group v-model="selected_color" size="mini">
                      <el-radio-button
                        v-for="color in colors"
                        :key="color.color"
                        :label="color.color"
                      >
                      </el-radio-button>
                    </el-radio-group>
                    <hr />
                  </div>

                  <div v-if="sizes.length">
                    <span>Size: </span>
                    <el-radio-group v-model="selected_size" size="mini">
                      <el-radio-button
                        v-for="size in sizes"
                        :key="size.size"
                        :label="size.size"
                      >
                      </el-radio-button>
                    </el-radio-group>
                    <hr />
                  </div>

                  <div>
                    <span>Quantity: </span>
                    <el-input-number
                      v-model="quantity_num"
                      @change="handleChange"
                      :min="1"
                      :max="1000000"
                      size="mini"
                    ></el-input-number>
                  </div>
                  <hr />

                  <div style="display: flex">
                    <button
                      class="btn btn-md addToCartBtn"
                      @click="
                        addToCartNow(
                          product.product_name,
                          product.product_price,
                          colors,
                          sizes,
                          selected_color,
                          selected_size,
                          quantity_num,
                          product.id,
                          product.main_image,
                          product.product_description
                        )
                      "
                    >
                      <strong>
                        <i class="fas fa-shopping-cart"></i>
                        Add to Cart
                      </strong>
                    </button>
                    <button
                      class="btn btn-md buyNowBtn ml-4"
                      @click="
                        addToBuyNow(
                          product.product_name,
                          product.product_price,
                          colors,
                          sizes,
                          selected_color,
                          selected_size,
                          quantity_num,
                          product.id,
                          product.main_image,
                          product.product_description
                        )
                      "
                    >
                      <strong>Buy Now</strong>
                    </button>
                  </div>
                  <hr />
                  <div>
                    <div style="display: flex; align-items: center">
                      <div
                        style="
                          width: 10px;
                          height: 10px;
                          margin-right: 20px;
                          cursor: pointer;
                          border-radius: 100%;
                          background-color: #dcae96;
                        "
                      ></div>
                      <div><small>Shipping included</small></div>
                    </div>
                    <div style="display: flex; align-items: center">
                      <div
                        style="
                          width: 10px;
                          height: 10px;
                          margin-right: 20px;
                          cursor: pointer;
                          border-radius: 100%;
                          background-color: #dcae96;
                        "
                      ></div>
                      <div>
                        <small><strong>Door Delivery</strong></small
                        ><br />
                        <small
                          >Ready for delivery between
                          <strong>3 business days</strong> when you order within
                          next 7hrs.</small
                        >
                      </div>
                    </div>
                    <div style="display: flex; align-items: center">
                      <div
                        style="
                          width: 10px;
                          height: 10px;
                          margin-right: 20px;
                          cursor: pointer;
                          border-radius: 100%;
                          background-color: #dcae96;
                        "
                      ></div>
                      <div><small>7 day money-back guarantee</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
          </div>
        </section>
      </article>

      <section>
        <ProductsComponent />
      </section>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import ProductsComponent from "../../components/home/products-component.vue";
import NavbarComponent from "../../components/navbar-component.vue";
import FooterComponent from "../../components/footer-component.vue";
export default {
  components: {
    NavbarComponent,
    FooterComponent,
    ProductsComponent,
  },
  data() {
    return {
      isFetching: true,
      isError: false,
      loading: true,
      loadingError: false,
      product: {},
      colors: [],
      sizes: [],
      productId: this.$route.params.productId,
      selected_color: "",
      selected_size: "",
      items: [],
      cartitem: {},

      current_currency: "",
      country: "",
      currency_code: "",
      kes_rate: "",
      tzs_rate: "",
      ssp_rate: "",
      rwf_rate: "",
      bif_rate: "",
      ugx_rate: "",
      usd_rate: "",

      currencyData: "",
      quantity_num: 1,
    };
  },

  mounted() {
    this.getOneProduct();
    this.getCurrencyRates();
    this.getCurrentCurrency();
    this.getCartItems();
  },

  watch: {
    "$route.params.productId": {
      handler: function (search) {
        this.getOneProduct();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    getCurrentCurrency() {
      this.current_currency = this.$store.state.current_currency;
    },

    formatCurrency(amount) {
      let currency = this.$store.state.current_currency;
      // currency = 'USD';

      if (currency == "KES") {
        let new_amount = Math.round((this.kes_rate / this.ugx_rate) * amount);
        return `KES ${this.formatNumber(new_amount)}`;
      } else if (currency == "UGX") {
        return `UGX ${this.formatNumber(amount)}`;
      } else if (currency == "TZS") {
        let new_amount = Math.round((this.tzs_rate / this.ugx_rate) * amount);
        return `TZS ${this.formatNumber(new_amount)}`;
      } else if (currency == "USD") {
        let new_amount = Math.round((this.usd_rate / this.ugx_rate) * amount);
        if (new_amount <= 1) {
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`;
        }
        return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`;
      } else if (currency == "SSP") {
        let new_amount = Math.round((this.ssp_rate / this.ugx_rate) * amount);
        return `SSP ${this.formatNumber(new_amount)}`;
      } else if (currency == "RWF") {
        let new_amount = Math.round((this.rwf_rate / this.ugx_rate) * amount);
        return `RWF ${this.formatNumber(new_amount)}`;
      } else if (currency == "BIF") {
        let new_amount = Math.round((this.bif_rate / this.ugx_rate) * amount);
        return `BIF ${this.formatNumber(new_amount)}`;
      } else {
        // default to UGX
        return `UGX ${this.formatNumber(amount)}`;
      }
    },

    async getCurrencyRates() {
      try {
        let request = await this.$http.get(
          "https://openexchangerates.org/api/latest.json?app_id=8f4b23638b764037902c6e67e559a0d0"
        );
        if (request.data) {
          // console.log(request.data);
          this.currencyData = request.data;
          // value for 1 dollar
          this.usd_rate = 1;
          this.ugx_rate = this.currencyData.rates.UGX;
          this.kes_rate = this.currencyData.rates.KES;
          this.tzs_rate = this.currencyData.rates.TZS;
          this.ssp_rate = this.currencyData.rates.SSP;
          this.rwf_rate = this.currencyData.rates.RWF;
          this.bif_rate = this.currencyData.rates.BIF;

          // console.log(`the current tzs_rate is ${this.tzs_rate}`);
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning(
            "EVRYTHINGIRLY FRONT END: Unexpected API response",
            {
              response: request.data,
              request,
            }
          );
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = true;
        this.isError = true;

        if (error.message === "Network Error") {
          console.log(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        console.log(
          "Fetching failed",
          "Unable to fetch Currency Rates now, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    handleChange(value) {
      console.log(value);
    },

    showIt(imgsrc) {
      document.getElementById("imageshow").src = imgsrc;
    },

    async getOneProduct() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(
          `product/${this.$route.params.productId}`
        );
        if (request.data.success) {
          this.product = request.data.product;
          if (
            request.data.product.colors != "" &&
            request.data.product.sizes != ""
          ) {
            this.colors = request.data.product.colors;
            this.colors = JSON.parse(this.colors);
            // console.log("Colors: " + this.colors);
            this.sizes = request.data.product.sizes;
            this.sizes = JSON.parse(this.sizes);
            // console.log("Sizes: " + this.sizes);
          } else {
            // console.log(` 😛 the product has no colors`)
          }
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning(
            "EVRYTHINGIRLY FRONT END: Unexpected API response",
            {
              response: request.data,
              request,
            }
          );
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = true;
        this.isError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Products now, please try again"
        );
      }
    },

    categoryDetail(categoryId) {
      this.$router.push({
        name: "CategoryProducts",
        params: {
          categoryId: categoryId,
        },
      });
    },

    addToBuyNow(
      item,
      price,
      colors,
      sizes,
      color,
      size,
      quantity,
      itemid,
      image,
      brief_description
    ) {
      this.cartitem = {
        product: {
          item: item,
          price: price,
          color: color,
          size: size,
          quantity: quantity,
          product_id: itemid,
          image: image,
          brief_description: brief_description,
        },
      };

      if (colors.length == 0 && sizes.length == 0) {
        //product Id: 92 Example
        localStorage.setItem("buy_item", `${JSON.stringify(this.cartitem)}`);
        this.$router.push({
          name: "BuyNow",
          query: {
            page: "fulfillment",
            product_price: price,
            quantity: quantity,
            total_price: price * quantity,
            product_name: item,
            pro: itemid,
            image: image,
          },
        });
      } else if (colors.length && sizes.length == 0) {
        //product Id: 206 Example
        if (this.selected_color != "") {
          localStorage.setItem("buy_item", `${JSON.stringify(this.cartitem)}`);
          this.$router.push({
            name: "BuyNow",
            query: {
              page: "fulfillment",
              product_price: price,
              quantity: quantity,
              total_price: price * quantity,
              product_name: item,
              pro: itemid,
              image: image,
            },
          });
        } else {
          this.$notify({
            title: "Empty Color",
            message: "Please Choose Your Color",
            type: "warning",
          });
          return;
        }
      } else if (colors.length == 0 && sizes.length) {
        //product Id: .. Example
        if (this.selected_size != "") {
          localStorage.setItem("buy_item", `${JSON.stringify(this.cartitem)}`);
          this.$router.push({
            name: "BuyNow",
            query: {
              page: "fulfillment",
              product_price: price,
              quantity: quantity,
              total_price: price * quantity,
              product_name: item,
              pro: itemid,
              image: image,
            },
          });
        } else {
          this.$notify({
            title: "Empty Size",
            message: "Please Choose Your Size",
            type: "warning",
          });
          return;
        }
      } else if (colors.length && sizes.length) {
        //product Id: 83 Example
        if (this.selected_color == "" && this.selected_size == "") {
          this.$notify({
            title: "Empty Color & Size",
            message: "Please Choose Your Color & Size",
            type: "warning",
          });
          return;
        } else if (this.selected_color != "" && this.selected_size == "") {
          this.$notify({
            title: "Empty Size",
            message: "Please Choose Your Size",
            type: "warning",
          });
          return;
        } else if (this.selected_color == "" && this.selected_size != "") {
          this.$notify({
            title: "Empty Color",
            message: "Please Choose Your Color",
            type: "warning",
          });
          return;
        } else if (this.selected_color != "" && this.selected_size != "") {
          localStorage.setItem("buy_item", `${JSON.stringify(this.cartitem)}`);
          this.$router.push({
            name: "BuyNow",
            query: {
              page: "fulfillment",
              product_price: price,
              quantity: quantity,
              total_price: price * quantity,
              product_name: item,
              pro: itemid,
              image: image,
            },
          });
        }
      }
    },

    getCartItems() {
      if(localStorage.getItem("items") == null ) {
        localStorage.setItem("items", JSON.stringify(this.items));
      }else {
        this.items = JSON.parse(localStorage.getItem("items"));
      }
    },

    addToCartNow(
      item,
      price,
      colors,
      sizes,
      color,
      size,
      quantity,
      itemid,
      image,
      brief_description
    ) {
      this.cartitem = {
        product: {
          item: item,
          price: price,
          color: color,
          size: size,
          quantity: quantity,
          product_id: itemid,
          image: image,
          brief_description: brief_description,
        },
      };
      
        console.log(this.items);

      if (colors.length == 0 && sizes.length == 0) {
        //product Id: 92 Example
        console.log(this.items);
        console.log(this.cartitem);
        this.items.push(this.cartitem);
        localStorage.setItem("items", JSON.stringify(this.items));
        this.$router.push({
          name: "Cart",
        });
      } else if (colors.length && sizes.length == 0) {
        //product Id: 206 Example
        if (this.selected_color != "") {
          this.items.push(this.cartitem);
          localStorage.setItem("items", JSON.stringify(this.items));
          this.$router.push({
            name: "Cart",
          });
        } else {
          this.$notify({
            title: "Empty Color",
            message: "Please Choose Your Color",
            type: "warning",
          });
          return;
        }
      } else if (colors.length == 0 && sizes.length) {
        //product Id: .. Example
        if (this.selected_size != "") {
          this.items.push(this.cartitem);
          localStorage.setItem("items", JSON.stringify(this.items));
          this.$router.push({
            name: "Cart",
          });
        } else {
          this.$notify({
            title: "Empty Size",
            message: "Please Choose Your Size",
            type: "warning",
          });
          return;
        }
      } else if (colors.length && sizes.length) {
        //product Id: 83 Example
        if (this.selected_color == "" && this.selected_size == "") {
          this.$notify({
            title: "Empty Color & ize",
            message: "Please Choose Your Color & Size",
            type: "warning",
          });
          return;
        } else if (this.selected_color != "" && this.selected_size == "") {
          this.$notify({
            title: "Empty Size",
            message: "Please Choose Your Size",
            type: "warning",
          });
          return;
        } else if (this.selected_color == "" && this.selected_size != "") {
          this.$notify({
            title: "Empty Color",
            message: "Please Choose Your Color",
            type: "warning",
          });
          return;
        } else if (this.selected_color != "" && this.selected_size != "") {
          this.items.push(this.cartitem);
          localStorage.setItem("items", JSON.stringify(this.items));
          this.$router.push({
            name: "Cart",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.article1_space {
  padding: 100px 20px 50px 20px;
  background-image: linear-gradient(to right, #f9eded, #f5c3c2, #f9eded);
}

ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background-color: transparent;
}
ul.breadcrumb li {
  display: inline;
  cursor: pointer;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: ">";
}
ul.breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}
.product_detail_grid {
  display: grid;
  grid-template-columns: 60% 40%;
}

.product_detail_grid > div:first-child {
  height: 500px;
  display: grid;
  grid-template-columns: 20% 80%;
}

.product_detail_grid > div:nth-child(2) {
  text-align: left;
  padding-left: 20px;
}

.product_images {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.product_images > div {
  height: 120px;
  width: 100%;
  padding-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.product_images img {
  height: 120px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.product_images img:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.p_image img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 5px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.p_image img:hover {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.addToCartBtn {
  border: 2px solid rgb(247, 71, 100);
  color: rgb(247, 71, 100);
}

.buyNowBtn {
  background-color: rgb(247, 71, 100);
  border: 2px solid rgb(247, 71, 100);
  color: white;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .product_detail_grid > div:first-child {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    grid-auto-flow: row;
  }
  .product_detail_grid > div:first-child > div:nth-child(2) {
    grid-row: 1;
  }
  .p_image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .article1_space {
    padding: 80px 0px 10px 0px;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .product_detail_grid > div:first-child {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    grid-auto-flow: row;
  }
  .product_detail_grid > div:first-child > div:nth-child(2) {
    grid-row: 1;
  }
  .p_image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .article1_space {
    padding: 80px 0px 10px 0px;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .product_detail_grid > div:first-child {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    grid-auto-flow: row;
  }
  .product_detail_grid > div:first-child > div:nth-child(2) {
    grid-row: 1;
  }
  .product_detail_grid > div:nth-child(2) {
    margin-top: 30px;
    padding-left: 0px;
  }
  .p_image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .article1_space {
    padding: 80px 0px 10px 0px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .product_detail_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    grid-auto-flow: row;
  }
  .product_detail_grid > div:first-child {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    grid-auto-flow: row;
  }
  .product_detail_grid > div:first-child > div:nth-child(2) {
    grid-row: 1;
  }
  .product_detail_grid > div:nth-child(2) {
    margin-top: 30px;
    padding-left: 0px;
  }
  .p_image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  .article1_space {
    padding: 80px 0px 10px 0px;
  }

  .product_images {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .product_images > div {
    height: 120px;
    width: 100%;
    padding-right: 0px;
    border-radius: 5px;
  }
}
</style>