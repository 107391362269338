<template>
  <div class=" top_rankings">
    <div
      class="container mx-auto mt-5 available-tests-exams row"
      style="width: 100%"
    >
      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <h2 style="font-size: 1.2em; padding: 0px; margin: 0px;">
          {{ live_product_group.title ? live_product_group.title : "..." }}
        </h2>
        <i
          class="fas fa-chevron-right"
          style="cursor: pointer"
          @click="
            $router.push({ path: `/product-group/${live_product_group.id}` })
          "
        >
        </i>
      </div>
      <hr class="custom-classic-hr" />
      <div class="pr-0 mt-1 text-right col-12"></div>
      
      <div v-show="isFetching" class="eg_skeleton_carousel px-0 mt-3 col-12">
        <carousel ref="preloaderCarouselRef" :autoWidth="true" :dots="false">
          <div
            v-for="i in [1, 2, 3, 4, 5, 6]"
            :key="i"
            class="mr-3 skeleton-block skeleton-effect-wave"
            :navText="[
              `<i class='fas fa-chevron-left'></i>`,
              `<i class='fas fa-chevron-right'></i>`,
            ]"
          ></div>
        </carousel>
      </div>

      <div class="px-0 mt-3 col-12 eg_live_product_group_carousel">
        <!-- <div style="height: 10px; width: 10px; background-color: red"></div> -->
        <carousel
          :key="carouselKey"
          :autoWidth="true"
          :dots="false"
          :navText="[
            `<i class='fas fa-chevron-left'></i>`,
            `<i class='fas fa-chevron-right'></i>`,
          ]"
        >
          <div
            v-for="(item, index) in live_product_group_products"
            :key="index"
            v-loading="fetchingExamID == item.id"
            class="my-2 card ml-md-0 eg_live_product_group_block"
          >
            <div class="pt-0 pb-3">
              <div
                class="d-flex justify-content-center align-items-center"
                style="
                  height: 150px;
                  width: 100%;
                  background-color: rgba(255, 0, 0, 0.075);
                  cursor: pointer;
                "
                @click="$router.push({ path: `/product/${item.id}` })"
              >
                <img
                  :src="item.main_image"
                  style="height: 150px; width: 100%; object-fit: cover"
                />
              </div>
              <div
                class="mt-4 text-left col-12 text-truncate"
                style="color: var(--el-app-primary); text-transform: capitalize"
              >
                <strong>{{ item.product_name }}</strong>
              </div>
              <small
                style="color: #000; padding: 0px 10px; color: rgba(0, 0, 0, 0.6)"
                class="pb-1 text-left d-block text-truncate"
              >
                <strong>{{ formatCurrency(item.product_price) }}</strong>
                <br />
              </small>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
// import ScheduleLiveLessonComponent from "../components/schedule-live-lesson-component";
// import { format, parseISO } from "date-fns";

export default {
  components: {
    carousel,
    // ScheduleLiveLessonComponent,
  },
  data() {
    return {
      isFetching: true,
      isError: false,
      fetchingExamID: null,
      live_product_group: {},
      live_product_group_products: [],
      tests: [],
      carouselKey: 1,

        current_currency: "",
        country: "",
        currency_code: "",
        kes_rate: "",
        tzs_rate: "",
        ssp_rate: "",
        rwf_rate: "",
        bif_rate: "",
        ugx_rate: "",
        usd_rate: "",
    };
  },

  watch: {
    live_product_group_products() {
      //Force rerender of the component once we get some data!!!
      this.carouselKey++;
    },
  },

  mounted() {
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-prev"
    )[0].innerHTML = "<i class='fas fa-chevron-left'></i>";
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-next"
    )[0].innerHTML = "<i class='fas fa-chevron-right'></i>";

    this.fetchLiveProducts();
      this.getCurrencyRates();
      this.getCurrentCurrency();
  },

  methods: {
    async fetchLiveProducts() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(`product/group/live`);
        if (request.data.success) {
          //   this.courseworks = request.data.course_works;
          this.live_product_group = request.data.product_group;
          this.live_product_group_products = request.data.products_in_proup;
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = true;
        this.isError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Course Works now, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },

      formatCurrency(amount) {
        let currency = this.$store.state.current_currency;
        // currency = 'USD';

        if (currency == "KES") {
          let new_amount = Math.round((this.kes_rate / this.ugx_rate) * amount);
          return `KES ${this.formatNumber(new_amount)}`;
        } else if (currency == "UGX") {
          return `UGX ${this.formatNumber(amount)}`;
        } else if (currency == "TZS") {
          let new_amount = Math.round((this.tzs_rate / this.ugx_rate) * amount);
          return `TZS ${this.formatNumber(new_amount)}`;
        } else if (currency == "USD") {
          let new_amount = Math.round((this.usd_rate / this.ugx_rate) * amount);
          if (new_amount <= 1) {
            return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`;
          }
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`;
        } else if (currency == "SSP") {
          let new_amount = Math.round((this.ssp_rate / this.ugx_rate) * amount);
          return `SSP ${this.formatNumber(new_amount)}`;
        } else if (currency == "RWF") {
          let new_amount = Math.round((this.rwf_rate / this.ugx_rate) * amount);
          return `RWF ${this.formatNumber(new_amount)}`;
        } else if (currency == "BIF") {
          let new_amount = Math.round((this.bif_rate / this.ugx_rate) * amount);
          return `BIF ${this.formatNumber(new_amount)}`;
        } else {
          // default to UGX
          return `UGX ${this.formatNumber(amount)}`;
        }
      },

      async getCurrencyRates() {

        try {

          let request = await this.$http.get('https://openexchangerates.org/api/latest.json?app_id=8f4b23638b764037902c6e67e559a0d0');
          if (request.data) {
            console.log(request.data);
            this.currencyData = request.data;
            // value for 1 dollar
            this.usd_rate = 1;
            this.ugx_rate = this.currencyData.rates.UGX;
            this.kes_rate = this.currencyData.rates.KES;
            this.tzs_rate = this.currencyData.rates.TZS;
            this.ssp_rate = this.currencyData.rates.SSP;
            this.rwf_rate = this.currencyData.rates.RWF;
            this.bif_rate = this.currencyData.rates.BIF;

            console.log(`the current tzs_rate is ${this.tzs_rate}`)
            this.isFetching = false;
            this.isError = false;
          } else {
            this.$rollbar.warning("EVRYTHINGIRLY FRONT END: Unexpected API response", {
              response: request.data,
              request,
            });
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true;
          this.isError = true;

          if (error.message === "Network Error") {
            console.log(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }

          console.log(error);

          console.log(
            "Fetching failed",
            "Unable to fetch Currency Rates now, please try again"
          );
        } finally {
          this.loading = false;
        }
      },
      
      getCurrentCurrency() {
        this.current_currency = this.$store.state.current_currency;
      },
  },
};
</script>


<style scoped>
  .btn.disabled,
  .btn:disabled {
    opacity: 0.35;
  }

.top_rankings {
  background-color: rgba(255, 192, 203, 0.5);
  padding-top: 20px;
  padding-bottom: 20px;
}

</style>

<style>

  .eg_skeleton_carousel .skeleton-block {
    width: 230px; height: 150px; border-radius: 5px
  }

  .eg_live_product_group_carousel .eg_live_product_group_block {
    background-color: #ec8f97;
    width: 230px; height: auto;
    margin: 0px 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
    border-radius: 5px;
  }

  .eg_live_product_group_carousel .eg_live_product_group_block:hover {
    -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
    box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
    transition: 0.5s;
  }

  hr.custom-classic-hr {
    /* Gradient transparent-color-transparent */
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.205),
      rgba(0, 0, 0, 0)
    );
  }
  .available-tests-exams .el-progress__text {
    font-size: 12px !important;
  }
  .owl-prev {
    position: absolute;
    left: -21px;
    top: 44%;
    padding: 6px 11px !important;
    border-radius: 30px !important;
  }

  .owl-next {
    position: absolute;
    right: -21px;
    top: 44%;
    padding: 6px 11px !important;
    border-radius: 30px !important;
  }

  

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {

  .eg_skeleton_carousel .skeleton-block {
    width: 120px; height: 150px;
  }
  .eg_live_product_group_carousel .eg_live_product_group_block {
    width: 230px; height: auto;
  }

}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  
  .eg_skeleton_carousel .skeleton-block {
    width: 120px; height: 150px;
  }
  .eg_live_product_group_carousel .eg_live_product_group_block {
    width: 120px; height: auto;
    margin: 0px 5px;
  }
  
}
</style>