<template>
  <div class="home">
    <NavbarComponent/>
    <CarouselComponent />
    <ActiveProductGroupComponent />
    <ProductsComponent />
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from "../../components/navbar-component.vue";
import CarouselComponent from "../../components/home/carousel-component.vue";
import ProductsComponent from "../../components/home/products-component.vue";
import ActiveProductGroupComponent from "../../components/home/active-product-group-component.vue";
import FooterComponent from "../../components/footer-component.vue";

export default {
  components: {
    NavbarComponent,
    CarouselComponent,
    ProductsComponent,
    ActiveProductGroupComponent,
    FooterComponent,
  },

  data() {
    return {
      value1: true,
      value2: true,
    };
  },

};
</script>
