<template>
  <footer>
    <article>
      <section>
        <div class="pt-5 pb-5" style="background-color: #f74764;">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <img src="../assets/images/eglogo5.png" width="70%" />
              </div>
              <div class="col-md-6">
                <small style="font-weight: 600">NEW TO EVRYTHIGIRLY?</small><br />
                <small>
                  Subscribe to our newsletter to get updates on our latest offers!
                </small><br />
                <div class="" style="display: flex; margin-top: 10px">
                  <input type="text" class="form-control" name="" />
                  <button
                    class="btn btn-sm"
                    style="background-color: black; color: white"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div class="col-md-3">
                <small style="font-weight: 600">DOWNLOAD EVRYTHIGIRLY APP</small
                ><br />
                <small>Get access to exclusive offers!</small><br />
                <div style="display: flex; margin-top: 10px; cursor: pointer">
                  <img
                    src="../assets/images/googleplay.png"
                    width="132px"
                  />
                  <img
                    src="../assets/images/appstore.png"
                    width="132x"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article>
      <section>
        <div class="pt-5 pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <small style="font-weight: 600">LET US HELP YOU</small><br />
                <ul>
                  <li>
                    <small>Help Center</small>
                  </li>
                  <li>
                    <small>How to shop on Evrythingirly?</small>
                  </li>
                  <li>
                    <small>Shipping and delivery</small>
                  </li>
                  <li>
                    <small>Return policy</small>
                  </li>
                  <li>
                    <small>Corporate & bulk purchase</small>
                  </li>
                  <li>
                    <small>Report a product</small>
                  </li>
                </ul>
              </div>
              <div class="col-md-3">
                <small style="font-weight: 600">ABOUT EVRYTHIGIRLY?</small
                ><br />
                <ul>
                  <li>
                    <small>About us.</small>
                  </li>
                  <li>
                    <small>EG Careers.</small>
                  </li>
                  <li>
                    <small>EG Express.</small>
                  </li>
                  <li>
                    <small>Terms and conditions.</small>
                  </li>
                  <li>
                    <small>Privacy policy.</small>
                  </li>
                </ul>
              </div>
              <div class="col-md-3">
                <small style="font-weight: 600"
                  >MAKE MONEY WITH EVRYTHIGIRLY?</small
                ><br />
                <ul>
                  <li>
                    <small>Sell on EG.</small>
                  </li>
                  <li>
                    <small>Become a Sales Consultant.</small>
                  </li>
                  <li>
                    <small>Order & Pickup point application.</small>
                  </li>
                </ul>
              </div>
              <div class="col-md-3">
                <small style="font-weight: 600">CONTACT US</small><br />
                <ul>
                  <li>
                    <i class="fas fa-map-marker-alt"></i> Pentagon City Acarde
                    PC475 Kampala, Uganda
                  </li>
                </ul>
                <ul>
                  <li><i class="fab fa-whatsapp"></i> : +256 (0) 783 096053</li>
                  <li>
                    <i class="fas fa-phone-volume"></i> : +256 (0) 756 577893
                  </li>

                  <li>
                    <a href="mailto:info@evrythingirly.com">
                      <i class="fas fa-envelope"></i> :
                      info@evrythingirly.com</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article>
      <section>
        <div class="pt-5 pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <small style="font-weight: 600">PAYMENT</small><br />
                <div class="payments_methods">
                  <img src="../assets/images/payments/visa.svg" />
                  <img src="../assets/images/payments/master.svg" />
                  <img src="../assets/images/payments/mtn.svg" />
                  <img src="../assets/images/payments/airtel.svg" />
                  <img src="../assets/images/payments/mpesa.svg" />
                </div>
              </div>
              <div class="col-md-3">
                <small style="font-weight: 600">FOLLOW US</small><br />
                <li>
                  <a
                    style="color: white"
                    href="https://www.facebook.com/girlshoppingmalleastafrica"
                    target="_blank"
                    title="Facebook"
                    ><i class="fab fa-facebook-f"></i>
                  </a>
                  &nbsp;&nbsp;
                  <a
                    style="color: white"
                    href="https://www.instagram.com/everythingirly_eastafrica/"
                    target="_blank"
                    title="Instagram"
                    ><i class="fab fa-instagram"></i>
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>

    <section>
       <a href="https://wa.me/+256 783 096053/?text=Hello, Kisubi Forest Cottages" class="float" target="_blank">
          <!--<i class="fa fa-whatsapp my-float"></i>-->
          <i class="fab fa-whatsapp my-float"></i>
        </a>
    </section>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>



.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
}
  footer {
    text-align: left;
    background-color: #ec9694;
  }
  .payments_methods {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
  }
  .payments_methods > img {
    display: flex;
    align-items: flex-start;
    background-color: #f8e2e2;
    padding: 2px 0px;
    width: 88px;
    height: 22px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
footer ul li {
  display: block;
  color: #f8f7f7;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8em;
  margin: 0.7em 0;
    list-style-type: none;
    cursor: pointer;
}
footer ul li a {
  color: #f8f7f7;
  font-size: 13px;
  display: block;
  padding: 5px 0px;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
footer ul li a:hover {
  color: white;
}

/*##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) { }

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .payments_methods {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .payments_methods {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>